import React, { useState} from 'react'
import PropTypes from 'prop-types'

import data from './constants/gigavoltContent';
import TemplateFooter from './components/TemplateFooter';
import SupportLandingTemplate from './components/SupportLandingTemplate';

function LandingSupport(props) {
  const { gigavolt } = data; 
  return (
    <>
      <SupportLandingTemplate
        {...data}
      />
      <TemplateFooter
        {...data}
      />
    </>
  )
}

LandingSupport.propTypes = {}

export default LandingSupport

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { RecruiterProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import { titleCase } from "title-case";
import CreateSearchQueryModal from "./CreateSearchQueryModal";
import EditSearchQueryModal from "./EditSearchQueryModal";
import DeleteModal from "./DeleteModal";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";
import { MdDeleteForever, MdEdit } from "react-icons/md"


class RecruiterSearches extends Component {
  state = {
    recruiterSearchQueries: [],
    modalShow: "",
    editMode: false,
    editSearchQueryId: "",
    deleteSearchQueryId: "",
  };

  checkSubscriptionStatus = () => {
    API.get(`/UserProfiles/${this.props.userToken.userId}`)
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => {
        if (!this.state.recruiterProfileId && this.state.recruiterEnabled) {
          API.post(`/RecruiterProfiles`, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            companyName: this.state.companyName,
            jobTitle: this.state.jobTitle,
            companyEmail: this.state.companyEmail,
            phoneNumber: this.state.phoneNumber,
            linkedIn: this.state.linkedIn,
            userProfileId: this.props.userToken.userId,
            pushId: this.state.pushId,
          }).then((response) => {
            API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
              recruiterEnabled: true,
              recruiterProfileId: response.data.id,
              firstTimeRecruiterMode: false,
              newRecruiter: false,
            });
          });
        }
      })
      .then(() => {
        if (
          this.state.recruiterProfileId &&
          this.state.recruiterEnabled &&
          this.state.customerId
        ) {
          API.get(`/UserSubscriptions/listSubscriptions`, {
            customerId: this.state.customerId,
          }).then((response) => {
            // return list of active subscriptions
            if (response.data.subscriptions.length === 0) {
              API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
                recruiterEnabled: false,
                firstTimeRecruiterMode: false,
                recruiterProfileId: "",
              });
            }
          });
        }
      });
  }

  componentDidMount() {
    this.checkSubscriptionStatus();
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterSearchQueries`
    ).then((response) => {
      this.setState({
        recruiterSearchQueries: response.data,
      });
    });
  }

  handleModalShow = (val) => {
    this.setState({ modalShow: val });
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterSearchQueries`
    ).then((response) => {
      this.setState({
        recruiterSearchQueries: response.data,
      });
    });

    if (val === "") {
      this.setState({
        editSearchQueryId: "",
        deleteSearchQueryId: "",
      });
    }
  };

  handleEditMode = (val) => {
    this.setState({
      editMode: val,
    });
  };

  render() {
    return (
      <Container>
        <NavBarRecruiterWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <RecruiterProfileCard {...this.props} />
          </Col>
          <Col>
            <MatchesContainer>
              <MatchesHeadingContainer>
                <MatchesHeadingMenuItem
                  onClick={() => this.handleModalShow("create")}
                >
                  Create New Search
                </MatchesHeadingMenuItem>
                <MatchesHeadingMenuItem
                  onClick={() =>
                    this.setState({
                      editMode: !this.state.editMode,
                    })
                  }
                >
                  {this.state.editMode ? "Cancel" : "Edit"}
                </MatchesHeadingMenuItem>
              </MatchesHeadingContainer>
              <MatchesListContainer>
                {this.state.recruiterSearchQueries.map(
                  (recruiterSearchQuery) => (
                    <MatchesListItemContainer>
                      <MatchesItemDetailsContainer>
                        <MatchesItemName>
                          {recruiterSearchQuery.searchTitle}
                        </MatchesItemName>
                        <MatchesItemJobTitle>
                          {recruiterSearchQuery.hiringCompany}
                        </MatchesItemJobTitle>
                        <MatchesItemLocation>
                          {recruiterSearchQuery.cities
                            .map((city) => city.value)
                            .join(", ")}{" "}
                          | ${recruiterSearchQuery.salaryMax}
                        </MatchesItemLocation>
                      </MatchesItemDetailsContainer>
                      <div>
                        {this.state.editMode ? (
                          <div>
                            <Button
                              className="mr-3 bg-danger border-danger"
                              onClick={() => {
                                this.setState({
                                  modalShow: "delete",
                                  deleteSearchQueryId: recruiterSearchQuery.id,
                                });
                              }}
                            >
                              <MdDeleteForever
                                style={{
                                  color: "#EDF2F2",
                                  fontSize: 24,
                                }}
                              />
                              {/* Delete */}
                            </Button>
                            <Button
                              className="bg-secondary border-secondary"
                              onClick={() => {
                                this.setState({
                                  modalShow: "edit",
                                  editSearchQueryId: recruiterSearchQuery.id,
                                });
                              }}
                            >
                              <MdEdit
                                style={{
                                  color: "#EDF2F2",
                                  fontSize: 24,
                                }}
                              />
                              {/* Edit */}
                            </Button>
                          </div>
                        ) : (
                          <Link
                            to={`/recruiter/searches/${recruiterSearchQuery.id}`}
                          >
                            <Button className=" ">View Matches</Button>
                          </Link>
                        )}
                      </div>
                    </MatchesListItemContainer>
                  )
                )}
              </MatchesListContainer>
            </MatchesContainer>
          </Col>
        </Row>
        <CreateSearchQueryModal
          {...this.props}
          modalShow={this.state.modalShow}
          handleModalShow={this.handleModalShow}
        />
        <EditSearchQueryModal
          {...this.props}
          modalShow={this.state.modalShow}
          handleModalShow={this.handleModalShow}
          handleEditMode={this.handleEditMode}
          editSearchQueryId={this.state.editSearchQueryId}
        />
        <DeleteModal
          {...this.props}
          modalShow={this.state.modalShow}
          handleModalShow={this.handleModalShow}
          deleteSearchQueryId={this.state.deleteSearchQueryId}
          handleEditMode={this.handleEditMode}
        />
      </Container>
    );
  }
}

const MatchesContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
`;

const MatchesHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const MatchesHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #47A836;
  font-size: 14px;
  cursor: pointer;
`;

const MatchesListContainer = styled.div``;
const MatchesListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const MatchesItemImage = styled.img``;
const MatchesItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const MatchesItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const MatchesItemJobTitle = styled.p`
  color: #121a50;
  font-size: 14px;
  margin: 0;
`;
const MatchesItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
`;

export default RecruiterSearches;

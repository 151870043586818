import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { Container, Row, Col, Modal, Spinner } from "react-bootstrap";

import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import ProductCard from "./ProductCard";
import API from "../API";

const appearance = {
  theme: "flat",
  variables: {
    colorPrimary: "#278134",
    colorBackground: "#ffffff",
    colorText: "#121A50",
    colorDanger: "#dc3545",
    fontFamily: "Montserrat, system-ui, sans-serif",
    fontSizeBase: "14px",
    spacingUnit: "6px",
    borderRadius: "4px",
    spacingGridRow: "30px",
  },
  rules: {
    ".Input": {
      border: "solid 1px #D3D3D3",
    },
  },
};

const stripePromise = loadStripe("pk_live_Gc2o0YnlqBQ6A5lRmAbRHzQu");
const loader = "auto";

export default function StripeCheckoutScreen({
  email,
  firstName,
  lastName,
  grantHiring,
  userToken,
  selectedPrice,
  coupon
}) {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    API.post(`/UserSubscriptions`, {
      emailAddress: email,
      priceID: selectedPrice.id,
      name: firstName + " " + lastName,
      userProfileId: userToken.userId,
      coupon: coupon ? coupon : ""
      }).then((response) => {
        API.patch(`/UserProfiles/${userToken.userId}`, {
          customerId: response.data.customerId,
        })

        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
          setTimeout(() => {
            setIsLoading(false);
          }, 1500)        
        }
        if (!response.data.clientSecret && response.data.subscriptionId && coupon) {
          setClientSecret(null);
          setTimeout(() => {
            setIsLoading(false);
            grantHiring();
          }, 1500)
        }

      }).catch((error) => {
        console.error(error);
      });
  }, [email, firstName, lastName, selectedPrice, userToken.userId, coupon, grantHiring]);

  return (
    <>
        {isLoading ? (
           <Container>
              <Row style={{height: "40vh", alignItems: 'center', justifyContent: 'center'}}>
                <Spinner animation="grow" variant="success"  />
              </Row>
           </Container>
        ) : (
        <Modal show={true} dialogClassName="modal-stripe">
        <Modal.Body className="p-0">
          <Container className="p-0" fluid>
            <Row>
              {selectedPrice && (
                <Col xs={12} xl={5}>
                  <ProductCard selectedPrice={selectedPrice} />
                </Col>
              )}

              <Col xs={12} xl={7} className="bg-white">
                <div
                  style={{
                    color: "#47A836",
                    fontSize: 20,
                    fontWeight: "600",
                    marginTop: "40px",
                    paddingLeft: "15px",
                  }}
                >
                  Payment Details
                </div>
                <br />

                {clientSecret && stripePromise && (
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, appearance, loader }}
                  >
                    <CheckoutForm
                      userToken={userToken}
                      callback={grantHiring}
                    />
                  </Elements>
                )}
              </Col>
            </Row>
            </Container>
        </Modal.Body>
        </Modal>
        )}
    </>
  );
}
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment";
import BottomNavigation from "BottomNavigation";
import { BrowserView, MobileOnlyView } from "react-device-detect";

class MessagesScreen extends Component {
  state = {
    candidateMatches: [],
    messages: [],
    messageInput: "",
    userMatch: {
      recruiter: {
        firstName: "",
        lastName: "",
        avatar: {
          slug: "",
        },
      },
      candidateVeil: true,
      searchQuery: {
        hiringCompany: "",
      },
    },
  };
  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
      "include": {"candidateMatches": ["recruiter", "searchQuery"] }
    }`
    ).then((response) => {
      this.setState(response.data);
    });
    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
        "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
    }`
    ).then((response) => {
      this.setState({
        userMatch: response.data,
        messages: response.data.messages,
      });
      this.scrollToBottom();
    });

    this.timer = setInterval(() => this.getMessages(), 1000);
    // this.timer2 = setInterval(() => this.getUserMatch(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
    // this.timer2 = null;
  }

  getMessages = () => {
    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
        "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
    }`
    ).then((response) => {
      this.setState({
        userMatch: response.data,
        messages: response.data.messages,
      });
      this.scrollToBottom();
    });
  };

  getUserMatch = () => {
    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
      "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
  }`
    ).then((response) => {
      console.log(response.data);
      this.setState(response.data);
    });
  };

  componentDidUpdate() {}

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    API.post(`/UserMessages`, {
      messageFromId: this.props.userToken.userId,
      messageToId: this.state.userMatch.recruiter.id,
      matchId: this.props.match.params.userMatchId,
      message: this.state.messageInput,
      email: this.state.userMatch.recruiter.email,
      pushId: this.state.userMatch.recruiter.pushId,
      to: "recruiter",
    });
    this.setState({
      messageInput: "",
    });
  };

  renderMessage = (message) => {
    if (message.messageFromId === this.props.userToken.userId) {
      return (
        <MyChatContainer>
          <MyChatMessageContainer>
            <MyChatMessage>{message.message}</MyChatMessage>
            <MyChatMessage style={{ fontSize: 10 }}>
              {moment(message.createdAt).fromNow()}
            </MyChatMessage>
          </MyChatMessageContainer>
        </MyChatContainer>
      );
    } else {
      return (
        <TheirChatContainer>
          <TheirChatImage>
            <Avatar
              width={50}
              height={50}
              character={message.messageFrom.avatar.slug}
            />
          </TheirChatImage>
          <TheirChatMessageContainer>
            <TheirChatMessage>{message.message}</TheirChatMessage>
            <TheirChatMessage style={{ fontSize: 10 }}>
              {moment(message.createdAt).fromNow()}
            </TheirChatMessage>
          </TheirChatMessageContainer>
        </TheirChatContainer>
      );
    }
  };

  scrollToBottom = () => {
    // this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
  };

  handleUnveil = () => {
    API.patch(`/UserMatches/${this.props.match.params.userMatchId}`, {
      candidateVeil: false,
    });
    this.getUserMatch();
  };

  handleVeil = () => {
    API.patch(`/UserMatches/${this.props.match.params.userMatchId}`, {
      candidateVeil: true,
    });
    this.getUserMatch();
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row className="mt-4" noGutters={true}>
          <Col
            md={3}
            style={{
              borderRight: "1px solid #f5f5f5",
            }}
          >
            <MessagesListWrapper>
              {/* <MessagesListSearchContainer>
                <MessagesSearch placeholder="Search message.." />
              </MessagesListSearchContainer> */}
              <MessagesListContainer>
                {this.state.candidateMatches.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#999",
                        textAlign: "center",
                      }}
                    >
                      You don't have any matches right now. Please check back
                      again later!{" "}
                    </p>
                  </div>
                )}
                {this.state.candidateMatches.map(
                  (candidateMatch) =>
                    candidateMatch.candidateStatus !== "declined" && (
                      <Link to={"/messages/" + candidateMatch.id}>
                        <MessagesListItemContainer
                          active={
                            candidateMatch.id ===
                            this.props.match.params.userMatchId
                          }
                        >
                          <Avatar
                            width={50}
                            height={50}
                            character={candidateMatch.recruiter.avatar.slug}
                          />
                          <MessagesListItemDetailContainer>
                            <MessagesListItemDetailTitle
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              {candidateMatch.recruiter.firstName +
                                " " +
                                candidateMatch.recruiter.lastName}{" "}
                              at {candidateMatch.searchQuery.hiringCompany}
                            </MessagesListItemDetailTitle>
                            {/* <MessagesListItemDetailMsgPreview>
                              Just checking in, you available tomorrow?
                            </MessagesListItemDetailMsgPreview> */}
                          </MessagesListItemDetailContainer>
                        </MessagesListItemContainer>
                      </Link>
                    )
                )}
              </MessagesListContainer>
            </MessagesListWrapper>
          </Col>
          <Col md={6}>
            <ChatContainer>
              <ChatListContainer>
                {this.state.messages.map((message) =>
                  this.renderMessage(message)
                )}

                {this.state.messages.length === 0 &&
                  this.state.userMatch.recruiter.id && (
                    <MyChatContainer>
                      <MyChatMessageContainer style={{}}>
                        <MyChatMessage>
                          Congratulations on your new Match with this company!
                          Say something inviting to get the conversation
                          started.
                        </MyChatMessage>
                        <MyChatMessage style={{ fontSize: 10 }}>
                          {moment(this.state.userMatch.createdAt).fromNow()}
                        </MyChatMessage>
                      </MyChatMessageContainer>
                    </MyChatContainer>
                  )}

                <div ref={(el) => (this.messagesEndRef = el)} />
              </ChatListContainer>
              <ChatReplyContainer
                style={{ display: "flex", alignItems: "center", borderTop: "1px solid #f5f5f5" }}
              >
                <Form onSubmit={this.handleSubmit} style={{ flexGrow: 1 }}>
                  <ChatReplyMessageInput
                    name="messageInput"
                    onChange={this.handleChange}
                    placeholder="Reply"
                    value={this.state.messageInput}
                  />
                </Form>
                <Button
                  onClick={this.handleSubmit}
                  style={{
                    height: 40,
                    marginRight: 10,
                  }}
                >
                  Send
                </Button>
              </ChatReplyContainer>
            </ChatContainer>
          </Col>
          <Col
            md={3}
            style={{
              borderLeft: "1px solid #f5f5f5",
            }}
          >
            {this.state.userMatch.recruiter.firstName === "" ? (
              <ChatDetailContainer>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#999",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    After you have a match, select the Hiring Companies you want
                    to message. The profile for the Hiring Companies will show
                    here.
                  </p>
                </div>
              </ChatDetailContainer>
            ) : (
              <ChatDetailContainer>
                <ChatDetailImage>
                  <Avatar
                    character={this.state.userMatch.recruiter.avatar.slug}
                    width={100}
                    height={100}
                  />
                </ChatDetailImage>
                <ChatDetailName>
                  {this.state.userMatch.recruiter.firstName +
                    " " +
                    this.state.userMatch.recruiter.lastName}
                </ChatDetailName>

                {this.state.userMatch.recruiter.recruiterProfile.linkedIn !==
                  "" && (
                  <ChatDetailTitle>
                    <a
                      href={
                        this.state.userMatch.recruiter.recruiterProfile
                          .linkedIn || ""
                      }
                      target="_blank"
                    >
                      View LinkedIn Profile
                    </a>
                  </ChatDetailTitle>
                )}
                <ChatDetailDescription
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  Keep in touch and follow up with your recruiter regularly.
                </ChatDetailDescription>

                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Email</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.recruiter.email}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>

                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>First Name</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.recruiter.firstName}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>

                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Last Name</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.recruiter.lastName}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>

                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Company Name</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.searchQuery.hiringCompany}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>
              </ChatDetailContainer>
            )}
          </Col>
        </Row>
        <BottomNavigation />
      </Container>
    );
  }
}

export default MessagesScreen;

const MessagesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: scroll;
`;
const MessagesListSearchContainer = styled.div``;
const MessagesSearch = styled.input`
  border: none;
  padding: 20px;
  width: 100%;
  outline: none;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
`;
const MessagesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
  background: white;
`;
const MessagesListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: white;
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
  background-color: ${(props) => (props.active ? "#f5f5f5" : "#ffff")}
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const MessagesListItemImage = styled.img`
  border-radius: 50px;
  width: 50px;
  height: 50px;
`;
const MessagesListItemDetailContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  flex: 3;
`;
const MessagesListItemDetailTitle = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 12px;
`;
const MessagesListItemDetailMsgPreview = styled.p`
  color: silver;
  font-size: 12px;
  margin: 0;
`;
const MessagesListItemTime = styled.p`
  color: silver;
  font-size: 12px;
  margin: 0;
`;

const ChatContainer = styled.div`
  background: white;
`;
const ChatListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(800px - 62px);
  overflow-y: scroll;
`;
const MyChatContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
`;
const MyChatMessageContainer = styled.div`
  word-wrap: break-word;
  background: #47A836;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
`;
const MyChatMessage = styled.p`
  color: white;
  font-size: 14px;
  margin: 0;
`;
const TheirChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  margin: 10px;
`;
const TheirChatImage = styled.div`
  margin-right: 20px;
`;
const TheirChatMessageContainer = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
`;
const TheirChatMessage = styled.p`
  color: #999;
  font-size: 14px;
  margin: 0;
`;
const ChatReplyContainer = styled.div``;
const ChatReplyMessageInput = styled.input`
  outline: none;
  border: none;
  border-top: 1px solid #f5f5f5;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  color: #999;
`;

const ChatDetailContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 800px;
  max-height: 800px;
`;
const ChatDetailImage = styled.div`
  margin-top: 0px;
`;
const ChatDetailName = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin: 0;
  text-align: center;
  margin-top: 40px;
`;
const ChatDetailTitle = styled.p`
  color: #999;
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
`;
const ChatDetailDescription = styled.p`
  color: #999;
  font-size: 14px;
  font-style: italic;
  margin: 0;
  margin-top: 20px;
  text-align: center;
`;

const ChatDetailInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

const ChatDetailInfoContainerCol = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const ChatDetailInfoTitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 5px;
`;
const ChatDetailInfoResponse = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #999;
`;

const ChatDetailFooter = styled.div`
  width: 100%;
  margin-top: auto;
`;

import React, { Component } from "react";
import API from "API";
import styled from "styled-components";
import { Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as SpeakerIcon } from "images/icons/Feedback.svg";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment";
import scaleTwo from "images/logo/mycarelogo-white-2.png";
import { PrimaryLink } from "../Stories/Links.stories";

class UserFeed extends Component {
  state = {
    feed: [],
    profileComplete: false,
    profilePhoto: "",
    message: "",
  };

  componentDidMount() {
    API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
      (response) => {
        console.log("Feed Data: ", response.data);
        this.setState({
          feed: response.data,
        });
      }
    );

    // var myInt = setInterval(() => {
    //   API.get(`/UserProfiles/${this.props.userToken.userId}`).then(response => {
    //     this.setState({
    //       profileComplete: response.data.profileComplete
    //     });
    //   });
    // }, 2000);
    // this.setState({
    //   myInt: myInt
    // });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  componentDidUpdate() {
    // API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
    //   this.setState({
    //     feed: response.data,
    //     profileComplete: response.data.profileComplete,
    //   });
    // });
  }

  handleSubmit = () => {
    API.post(`/UserFeeds`, {
      message: this.state.message,
      attachment: this.state.profilePhoto,
      userId: this.props.userToken.userId,
    }).then(() => {
      this.setState({
        message: "",
        profilePhoto: "",
      });
      API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
        (response) => {
          this.setState({
            feed: response.data,
          });
        }
      );
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.myInt);
  }

  renderStatusMessage() {
    return this.props.profileComplete ? (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SpeakerIcon />
          <CompleteProfileCardTitle>You're all set!</CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          You've succesfully completed your profile! Check your report
          under Communication Style Report Snapshot to learn more about how hiring
          managers will see you.
        </CompleteProfileCardDescription>
        <Row>
          <Col>
            <Link to="/profile">
              <Button className="float-right">View Profile</Button>
            </Link>
          </Col>
        </Row>
      </CompleteProfileCard>
    ) : (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SearchIcon />

          <CompleteProfileCardTitle>
            Your profile is not discoverable to Hiring Companies within
            Cannabis Insider Jobs Talent Community
          </CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          Your profile is not complete. In order for you to become discoverable
          to Hiring Companies in Cannabis Insider Jobs Talent Community and view your Professional
          Communication Style Report, you must complete your profile.
        </CompleteProfileCardDescription>
        <Link to="/profile?onboard=true">
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            View Profile
          </Button>
        </Link>
      </CompleteProfileCard>
    );
  }

  render() {
    return (
      <div>
        {!this.props.recruiterMode && this.renderStatusMessage()}

        <CompleteProfileCard style={{ marginTop: 20 }}>
          <CompleteProfileCardTitleContainer>
            <NotificationIcon />
            <CompleteProfileCardTitle>
              Enable Notifications
            </CompleteProfileCardTitle>
          </CompleteProfileCardTitleContainer>
          <CompleteProfileCardDescription>
            We don't communicate a lot to you, but when we do, it's important because you have a match!
          </CompleteProfileCardDescription>
          <Row>
            <Col>
              <Link to="/settings/notifications">
                <Button className="float-right">Go To Notifications</Button>
              </Link>
            </Col>
          </Row>
        </CompleteProfileCard>

        {/* {this.props.location.pathname !== "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "white",
              marginBottom: 20,
              padding: 15,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  placeholder="What would you like to post?"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                  }}
                ></textarea>
              </div>
              {this.state.profilePhoto === "" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <Dropzone onDrop={this.uploadFile}>
                    {({ getRootProps, getInputProps }) => (
                      <StyledSection>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{ margin: 0 }} className="text-muted">
                            Add Attachment
                          </p>
                        </div>
                      </StyledSection>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <div>
                    <p
                      onClick={() => this.setState({ profilePhoto: "" })}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      Remove
                    </p>
                  </div>
                  <ProfilePhoto src={this.state.profilePhoto} />
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  flex: "0 0 auto",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={this.handleSubmit} className="float-right">
                  Post
                </Button>
              </div>
            </div>
          </div>
        )} */}

        {/*<NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider Jobs Talent Community</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 1 at 1:14 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to the Cannabis Insider Jobs Talent Community anonymous talent community! The Cannabis Insider Jobs Talent Community platform was designed for the more experienced battery industry professional. Battery industry professionals have the ability to share their career experience and unique desires anonymously, which removes discrimination and implicit bias. As a result, Hiring Professionals can prioritize equality of opportunity in their hiring practices and connect with talent they would not find on traditional job board sites.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 14th, 2023 at 2:00 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to Cannabis Insider Jobs Talent Community!<br /><br />
              As a cannabis-oriented platform, Cannabis Insider Jobs Talent community strives to build awareness among both people already in the cannabis field and skilled candidates from other areas of the industry. The Cannabis Insider Jobs community allows Cannabis Talent to share their unique desires for career advancement anonymously, which removes discrimination and implicit bias from the hiring process.
              As a result, Cannabis Insider Jobs Hiring Partners can prioritize equality of opportunity in their hiring practices and connect with Cannabis Talent they would not find on traditional job boards.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}


        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
          <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Samantha Nolan</NewsFeedCardName>
              <NewsFeedCardJob>
                Advanced Personal Branding Strategist and Career Expert, founder and CEO of Nolan Branding
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 17th, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>Returning to work: Resume critique provides direction on opportunities for improvement</strong>
              <br /> <br />
              <strong>Dear Sam:</strong> I am struggling to write about my last job and don’t know why. I have a degree in communications, yet I can’t figure out what to say about what is probably the most impressive job I have had in my young journey...
              <br /> <br />
              <a
                href="https://www.nj.com/business/2023/09/returning-to-work-resume-critique-provides-direction-on-opportunities-for-improvement.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  paddingRight: 5,
                }}
              >
                <PrimaryLink>Read More &rarr;</PrimaryLink>
              </a>
              <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
          <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Samantha Nolan</NewsFeedCardName>
              <NewsFeedCardJob>
                Advanced Personal Branding Strategist and Career Expert, founder and CEO of Nolan Branding
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 14th, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>How to describe your dream job, even if this job interview doesn’t fit the mold</strong>
              <br /> <br />
              <strong>Dear Sam:</strong> I was recently asked about my dream job in a job interview. I have never been asked such a question, and given I was interviewing for a position that had nothing to do with my dream, I knew I looked like a deer in the headlights. I am not in a place to pursue my dream at the moment. I need a position...
              <br /> <br />
              <a
                href="https://www.nj.com/business/2023/09/how-to-describe-your-dream-job-even-if-this-job-interview-doesnt-fit-the-mold.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  paddingRight: 5,
                }}
              >
                <PrimaryLink>Read More &rarr;</PrimaryLink>
              </a>
              <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
          <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Samantha Nolan</NewsFeedCardName>
              <NewsFeedCardJob>
                Advanced Personal Branding Strategist and Career Expert, founder and CEO of Nolan Branding
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>November 7th, 2023 at 10:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>How do I explain leaving a job before I have secured another job?</strong>
              <br /> <br />
              <strong>Dear Sam:</strong> I recently chose to leave my job because I no longer saw a fit with my skills. I was hired 18 months ago, and there have been several layoffs since then. As a result of those layoffs, I have been asked to assume more and more responsibilities. I don’t mind adding functions...
              <br /> <br />
              <a
                href="https://www.nj.com/business/2023/09/how-do-i-explain-leaving-a-job-before-i-have-secured-another-job.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  paddingRight: 5,
                }}
              >
                <PrimaryLink>Read More &rarr;</PrimaryLink>
              </a>
              <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
          <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider</NewsFeedCardName>
              <NewsFeedCardJob>
                Resources
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 18th, 2023 at 5:30 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <strong>Seeking professionals from law and accounting to health care and logistics, N.J. cannabis industry is hiring</strong>
              <br /><br />
              The cannabis industry needs people with skills that are directly related to the field as well as professionals from disciplines such as law, accounting, health care, logistics, software engineers, retail operations and sales...
              <br /><br />
              <a
                href="https://www.nj.com/sponsor-content/?scid=107304&prx_t=kMYBAAAAAAk80LA&prx_ro=s&ntv_ui=c83a2be9-f83d-4171-a04d-98d5ba24b7e4&ntv_ht=N4JaZAA&ntv_ccpvw=KSoCAMsIGAmboFAwDEDAk80LA"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  paddingRight: 5,
                }}
              >
                <PrimaryLink>Read More &rarr;</PrimaryLink>
              </a>
              <br />
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
          <NewsFeedCardImage style={{ borderRadius: 30 }} src={require("images/logo/cji-green-icon.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider</NewsFeedCardName>
              <NewsFeedCardJob>
                Resources
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 16th, 2023 at 5:30 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedVideoContainer>
            <br /><iframe width={"100%"} height={310} src={"https://www.youtube.com/embed/wflQrIM8CuU"} title="Cannabis Insider Jobs Talent Community - Resources" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </NewsFeedVideoContainer>
        </NewsFeedCard>
        
        

        
        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/mycarelogo-white-2.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Oregon's Governor Recognizes Direct Support Professionals</NewsFeedCardName>
              <NewsFeedCardJob>Shout Out to Oregon's DSPs</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 21 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
            Salute to another year of Oregon DSPs NEVER tiring of doing something good!
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/newpost.png")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>


        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage               style={{ width: "auto", borderRadius: 0 }}
 src={require("images/logo/cwlogo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Central Willamette Credit Union</NewsFeedCardName>
              <NewsFeedCardJob>Supporting you and your financial future</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Sept 4 at 9:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
             Central Willamette Credit Union is honored to support Oregon's Direct Support Pros for their unparalleled, and largely unsung, support to the I/DD community. In recognition of Direct Support Professionals Recognition Week 2022, Central Willamette Credit Union honors Oregon's Direct Support Pros for their unparalleled, and largely unsung, support to the I/DD community. Because of you, services in Oregon SOAR!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> 

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage               style={{ width: "auto", borderRadius: 0 }}
 src={require("images/logo/chemeketalogo.jpg")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chemeketa Community College</NewsFeedCardName>
              <NewsFeedCardJob>Associate of Applied Science Degree</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Sept 1 at 10:08 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
          Are you interested in earning a degree that will prepare you for work in support and leadership positions serving people with developmental disabilities?  Earn your Associate of Applied Science (Direct Support Professional Degree) from Chemeketa Community College.  Chemeketa will prepare you to work in both private and public agencies that work within communities to provide direct support and advocacy to their clients.  You will take courses that cover human development, leadership, and case management.  Apply today at <a href="https://go.chemeketa.edu/apply" target="_blank" >go.chemekta.edu/apply</a> or call 503-399-5058 for more information.
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/postimg.jpg")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}


        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/mycarelogo-white-2.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider Jobs Talent Community</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 20 at 1:08 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>ßß
          <NewsFeedCardDescriptionContainer>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/letsnotgettired.png")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              style={{ width: "auto", borderRadius: 0 }}
              src={require("images/oregon-logo.jpg")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Cannabis Insider Jobs Talent Community</NewsFeedCardName>
              <NewsFeedCardJob>ODDS</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 5:16 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
              Oregon is the name, DIVERSABILITY is our game!
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/iwork.png")}
            />
      
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {this.state.feed.map((feedItem) => {
          return feedItem.attachment === "" ? (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>{" "}
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
            </NewsFeedCard>
          ) : (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {" "}
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
              <NewsFeedVideoContainer>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={feedItem.attachment}
                />
              </NewsFeedVideoContainer>
            </NewsFeedCard>
          );
        })}

        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chris Hodges</NewsFeedCardName>
              <NewsFeedCardJob>CEO at Cannabis Insider Jobs Talent Community</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 4:18 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Hey, this is Chris from Cannabis Insider Jobs Talent Community. I'm excited to have you join our
              platform to make the journey of finding the perfect job for you
              effortless, and simple. Cannabis Insider Jobs Talent Community fights for you both in terms of
              privacy, and the best job for you. We hope you enjoy using Cannabis Insider Jobs Talent Community.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 8 at 7:14 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>

          <a
            style={{
              textDecoration: "none",
              color: "#000"
            }}
            href="https://www.gogig.com/blog/"
            target="_blank"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://www.gogig.com/hubfs/Interview-questions-man.jpg" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  How 2020 Is Reshaping the Way We Find Jobs
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Whether there’s a lull in your particular job market or bias
                  within the workforce, there’s a long list of reasons you may
                  be having trouble finding your ideal position. Here are four
                  big factors that may be stopping you from landing the right
                  opportunity in 2020
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard> */}

        {/* <BrowserView>
          <NewsFeedCard>
            <BadgesContainer>
              <GoGigLogo src={require("images/logo/GoGig.jpg")} />
              <DownloadOnGoGig
                style={{
                  marginLeft: 30,
                  flexGrow: 1
                }}
              >
                Download Cannabis Insider Jobs Talent Community
              </DownloadOnGoGig>
              <div>
                <GetOnApple src="https://selinko.com/site/wp-content/uploads/2017/10/itunes-app-store-logo-300x104.png" />
                <GetOnGoogle src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </div>
            </BadgesContainer>
          </NewsFeedCard>
        </BrowserView> */}

        <br />

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 1</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 2</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't worry about having to do all the hard work required with
              searching for your next position to move up. Use Cannabis Insider Jobs Talent Community effectively
              to make opportunities come to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig2.mp4#t=40" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 3</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Because of the anonymous nature of Cannabis Insider Jobs Talent Community, don't worry about your
              employers finding you. Your identity is completely anonymous.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig3.mp4#t=30" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 5</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't have any path to promotion? That's fine, Cannabis Insider Jobs Talent Community will help you
              get to the next level by bringing opportunities directly to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig5.mp4#t=10.0" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Cannabis Insider Jobs Talent Community
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 4</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our customer support is available all the time. If you have any
              issues with your profile, send our team an email at
              support@gogig.com
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig4.mp4#t=20" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */ }
      </div>
    );
  }
}

export default UserFeed;

const CompleteProfileCard = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoGigLogo = styled.img`
  width: 60px;
`;
const DownloadOnGoGig = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
`;
const GetOnApple = styled.img`
  height: 40px;
`;
const GetOnGoogle = styled.img`
  height: 60px;
`;

const NewsFeedBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  border: 1px solid #f5f5f5;
  align-items: center;
`;
const NewsFeedBlogImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit: cover;
`;
const NewsFeedBlogMetaContainer = styled.div``;
const NewsFeedBlogHeadline = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedBlogDescription = styled.p`
  font-size: 12px;
`;

const CompleteProfileCardTitleContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CompleteProfileCardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  margin-left: 10px;
`;
const CompleteProfileCardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const CompleteProfileCardButton = styled.button``;

const NewsFeedCard = styled.div`
  background: white;
  margin-top: 20px;
  padding: 20px;
`;
const NewsFeedCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
`;

const NewsFeedCardImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 10px;
`;

const NewsFeedCardDetailsContainer = styled.div`
  flex: 3;
`;
const NewsFeedCardName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedCardJob = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardTime = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardDescriptionContainer = styled.div``;
const NewsFeedCardDescription = styled.p`
  color: #000;
  margin: 0;
  font-size: 14px;
  padding: 10px;
`;

const NewsFeedVideoContainer = styled.div`
  video {
    width: 100%;
  }
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  text-align: center;
  height: 100px;
  border-radius: 8px;
`;

const ProfilePhoto = styled.img`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
`;

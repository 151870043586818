import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";

class BillingScreen extends Component {
  state = {
    subscription: {},
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      API.get(`/UserSubscriptions/listSubscriptions`, {
        customerId: response.data.customerId,
      }).then((response) => {
        // return list of active subscriptions
        if (response.data.subscriptions.length > 0) {
          let sub = response.data.subscriptions[0];
          this.setState({ subscription: sub.plan, status: sub.status });
        }
      });
    });
  }

  getStatusColor = () => {
    let color;
    switch (this.state.status) {
      case "active":
        color = "text-success";
        break;
      case "trialing":
        color = "text-muted";
        break;
      default:
        color = "text-danger";
        break;
    }
    return color;
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <SettingsHeading>
                <SettingsTitle>Billing</SettingsTitle>
              </SettingsHeading>
              {this.state.subscription && this.state.status && (
                <Form>
                  <Form.Row className="mt-3">
                    <Col>
                      <Form.Label className="mr-1">Plan:</Form.Label>
                      <Form.Label>
                        ${this.state.subscription.amount / 100}/
                        {this.state.subscription.interval}
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className="mr-1">
                        Subscription Status:
                      </Form.Label>
                      <Form.Label className={this.getStatusColor()}>
                        {this.state.status}
                      </Form.Label>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Text className="text-muted">
                        For assistance with cancellations or updating your billing
                        information, please contact support@gogig.com.
                      </Form.Text>
                    </Col>
                  </Form.Row>
                </Form>
              )}
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  .;
`;

export default BillingScreen;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller, scrollToTop } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Box, OutlinedInput } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';

const useStyles = makeStyles({
  select: {
    width: '90%',
    padding: '8.5px 14px',
    fontFamily: 'Montserrat',
  },
  outlined: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  popoverRoot: {
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
  },
  formRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  formInput: {
    borderRadius: 0,
    fontFamily: 'Montserrat',
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  errorOutline: {
    color: 'red',
  },
  errorOutlinedInput: {
    borderColor: 'red',
    '&:hover': {
      borderColor: '#b30000',
    },
  }
});

function RootBodyTemplate(props) {
  const {
    mobileLogo,
    contentHeroImg,
    contentHeroLogo,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonLink2,
    contentHeroButtonText,
    contentHeroButtonLink,
    recentlyAdded,
    contentBody,
    privacyForm,
  } = props;

  const [mobileRes, changeMobileRes] = useState(false);
  const styles = useStyles();

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // console.log(window.innerWidth)
    if (window.innerWidth > 900) {
      changeMobileRes(true);
    } else {
      changeMobileRes(false);
    }
  }, [])


  const bodyText = (
    <Grid
      className="bodyContent"
      container
      style={{
        margin: '2.5rem 0 ',
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          className={classes.bodyTitleContainer}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways Cannabis Insider Jobs Talent Community Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Have real conversations with hiring companies that express interest in you —no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            className={classes.heroImg}
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '50%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={6}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
              <img
                src={contentHeroLogo}
                alt=""
                width={"60%"}
                style={{marginBottom: '15px'}}
              />
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
            <Button
              className={classes.buttonSquareFilledGreen}
              href={contentHeroButtonLink}
              target="_blank"
              rel="noreferrer"
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '55px',
                fontSize: 16
              }}
            >
              GET STARTED
            </Button>
          </Grid>
        </Grid>
      </Grid>

      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            style={{
              fontSize: '1.438rem',
              fontFamily: 'Montserrat',
              fontWeight: '700',
            }}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSquareOutlinedGreen}
            href={contentHeroButtonLink2}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '20px 0',
              minWidth: '200px',
              height: '55px',
              fontSize: 16
            }}
          >
            SIGN UP NOW
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      { landingContent }

    </Grid>
  );
}

RootBodyTemplate.propTypes = {};

export default RootBodyTemplate;
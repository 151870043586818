import React, { Component } from "react";
import API from "API";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo/cannabis-transparent-icon.png";

import Lottie from "react-lottie";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import detective from "Lotties/detective-gogig.json";
import { Avatar } from "Stories/Avatars.stories.js";

class LandingScreen extends Component {
  render() {
    return (
      <>
        <BrowserView>
          <Container
            fluid={true}
            style={{
              backgroundImage: `url(${require("images/new-static-bg.png")})`,
              backgroundSize: "cover",
              opacity: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div class="overlay"></div>
            <SplitContainer
              style={{
                backgroundImage: `url(${require("images/landing-blob.png")})`,
                backgroundSize: "cover",
                padding: 110,
                zIndex: 100,
                paddingTop: 20,
                paddingBottom: 20,
                maxWidth: '80%',
              }}
            >
              <GoGigLogo src={logo} />
              <Title>Welcome to Cannabis Insider Jobs Talent Community</Title>
              <p style={{fontSize: "20px", color: "#121A50", marginBottom: "20px"}}>Start by telling us who you are</p>
              {/* <Subtitle>Cannabis Insider Jobs Talent Community has created a career advancement portal, powered by GoGig, designed for experienced battery industry professionals. Unlike traditional job board sites, you can remain anonymous and connect with companies partnered with Cannabis Insider Jobs Talent Community.</Subtitle> */}
              <Row>
                <Col>
                  <Link to="/signup">
                    <ItemContainer>
                      <Image src={require("images/landing-man.png")} />
                      <ItemTitle style={{ margin: 0, marginTop: 43 }}>
                        Candidate
                      </ItemTitle>
                    </ItemContainer>
                  </Link>
                </Col>
                <Col>
                  <Link to="/signup?recruiter=true">

                      <ItemContainer
                        style={{
                          paddingLeft: 40,
                          paddingRight: 40,
                          position: 'relative'
                        }}
                      >
                          {/* <Badge
                            pill
                            style={{
                              backgroundColor: "#47A836",
                              color: "#FFFFFF",
                              fontSize: "18px",
                              zIndex: 1,
                              position: "absolute",
                              top: '-3%',
                            }}
                          >Coming Soon!</Badge> */}
                          {/* style={{filter: 'grayscale(1)'}} */}
                          <Image src={require("images/landing-woman.png")}/> 
                          {/* color: '#7A7A7A90' */}
                          <ItemTitle style={{ margin: 0}}>
                            Hiring Company
                          </ItemTitle>
                          {/* <DisabledOverlay /> */}
                      </ItemContainer>

                  </Link>
                </Col>
              </Row>
              <FooterText>
                Already have an account?{" "}
                <Link to="/login">
                  <span style={{ fontWeight: "bold", color: "#121A50" }}>
                    Log In
                  </span>
                </Link>
                <a  href="https://www.gogig.com" target="_blank">
                  <p
                    style={{
                      fontSize: 12,
                      color: "#278134",
                      textAlign: "center",
                      position: "relative",
                      top: 10,
                    }}
                  >
                    Powered By GoGig
                  </p>
                </a>
              </FooterText>
            </SplitContainer>
          </Container>
        </BrowserView>
        <MobileOnlyView>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              backgroundImage: `url(${require("images/landing-blob.png")})`,
            }}
          >
            <GoGigLogo src={logo} />
            <p
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "#47A836",
                margin: 0,
                marginTop: 20,
                textAlign: 'center'
              }}
            >
              Introducing Cannabis Insider Jobs Talent Community
            </p>
            <p class="text-muted">Anonymous Career Advancement</p>

            {/* <Lottie
              options={{ autoplay: true, loop: true, animationData: detective }}
              width={"100%"}
              height={350}
            /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Avatar
                  character="neutral"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="girl"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="boy"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="penguin"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="elephant"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="cat"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="bear"
                  width={60}
                  height={60}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="woman3"
                  width={90}
                  height={90}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="woman4"
                  width={90}
                  height={90}
                  style={{ margin: 10 }}
                />
              </div>
              <div>
                <Avatar
                  character="man2"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="woman6"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
                <Avatar
                  character="man7"
                  width={70}
                  height={70}
                  style={{ margin: 10 }}
                />
              </div>
            </div>

            <div
              class="w-100"
              style={{ position: "fixed", bottom: 20, padding: 20 }}
            >
              <Link
                to="/login"
                class="btn btn-primary btn-block btn-lg"
                style={{color: '#47A836'}}
              >
                Log In
              </Link>
              <Link
                to="/signup"
                class="btn btn-primary btn-block btn-lg"
                style={{color: '#47A836'}}
              >
                Sign Up Free
              </Link>
            </div>
          </Container>
        </MobileOnlyView>
      </>
    );
  }
}

export default LandingScreen;

const DisabledOverlay = styled.div`
  background-color: #D3D3D360;
  position: absolute;
  width: 100%;
  height: 100%;
`

const SplitContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const GoGigLogo = styled.img`
  width: 75px;
  border-radius: 8px;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    margin-left: auto;
  }
`;

const Title = styled.h1`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  min-width: 200px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 50%;
`;

const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemContainer = styled.div`
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Image = styled.img``;

const ItemTitle = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: #121A50;
  margin-top: 10px;
`;

const FooterText = styled.p`
  font-size: 20px;
  margin-top: 20px;
  color: #121A50;
`;

import React from "react";
import styled from "styled-components";

export default {
  title: "Links",
};

export const PrimaryLink = styled.p`
  font-family: Montserrat, sans-serif;
  color: #47A836;
  font-size: ${(props) => (props.size ? props.size : "")};
  :hover {
    text-decoration: underline;
  }
`;

export const StoryPrimaryLink = () => <PrimaryLink>Link</PrimaryLink>;

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";
import BottomNavigation from "BottomNavigation";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

class MatchesScreen extends Component {
  state = {
    candidateMatches: [],
    modal: {
      viewableMatch: {
        recruiter: {
          firstName: "",
        },
        searchQuery: {
          hiringCompany: "",
        },
      },
      show: "skills | noSkills",
      hasSkills: false,
      skills: [],
    },
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": {"candidateMatches": ["searchQuery", "candidate", "recruiter", "skills"] }
    }`
    ).then((response) => {
      this.setState(response.data);
    });
  }

  handleAcceptMatch = (id) => {
    API.patch(`/UserMatches/${id}`, {
      candidateStatus: "accepted",
      candidateVeil: false,
    }).then((response) => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": {"candidateMatches": ["searchQuery", "candidate", "recruiter", "skills"] }
      }`
      ).then((response) => {
        this.setState(response.data);
      });
    });
  };

  onExperienceSliderChange = (sliderId, sliderValue) => {
    let selectedSkills = this.state.modal.skills;
    let updatedSkills = selectedSkills.map((skill) => {
      if (sliderId === skill.id) {
        skill.experienceMin = sliderValue;
      }
      return skill;
    });

    this.setState({
      modal: {
        skills: updatedSkills,
        ...this.state.modal,
      },
    });
  };

  handleAccept = () => {
    let recruiterSearchQueryEvaluation = this.state.modal.skills.map(
      (skill) => {
        return API.post(`/RecruiterSearchQuerySkillsEvaluators`, {
          experienceMax: skill.experienceMax,
          experienceMin: skill.experienceMin,
          skillName: skill.skillName,
          candidateId: this.state.modal.viewableMatch.candidate.id,
          recruiterId: this.state.modal.viewableMatch.recruiter.id,
          matchId: this.state.modal.viewableMatch.id,
        });
      }
    );

    Promise.all(recruiterSearchQueryEvaluation)
      .then((response) => {
        return API.patch(`/UserMatches/${this.state.modal.viewableMatch.id}`, {
          candidateStatus: "accepted",
          candidateVeil: false,
          candidateId: this.state.modal.viewableMatch.candidate.id,
          recruiterId: this.state.modal.viewableMatch.recruiter.id,
          searchQueryTitle:
            this.state.modal.viewableMatch.searchQuery.searchTitle,
          recruiterEmail: this.state.modal.viewableMatch.recruiter.email,
        });
      })
      .then((response) => {
        return API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
            "include": {"candidateMatches": ["recruiter", "searchQuery"] }
          }`
        );
      })
      .then((response) => {
        this.setState(response.data);
        this.handleClose();
      });
  };

  handleDecline = () => {
    API.delete(`/UserMatches/${this.state.modal.viewableMatch.id}`, {
      candidateStatus: "declined",
      candidateId: this.state.modal.viewableMatch.candidate.id,
      recruiterId: this.state.modal.viewableMatch.recruiter.id,
      searchQueryTitle: this.state.modal.viewableMatch.searchQuery.searchTitle,
      recruiterEmail: this.state.modal.viewableMatch.recruiter.email,
    }).then((response) => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": {"candidateMatches": ["recruiter", "searchQuery"] }
        }`
      ).then((response) => {
        this.setState(response.data);
        this.handleClose();
      });
    });
  };

  handleClose = () => {
    this.setState({
      modal: {
        viewableMatch: {
          recruiter: {
            firstName: "",
          },
          searchQuery: {
            hiringCompany: "",
          },
        },
        show: "skills | noSkills",
        hasSkills: false,
        skills: [],
      },
    });
  };

  viewMatch = (matchId) => {
    let match = this.state.candidateMatches.find(
      (candidateMatch) => candidateMatch.id === matchId
    );

    console.log(match);
    console.log(match.searchQuery);

    let skills = [];

    if (match.searchQuery.hasOwnProperty("skills")) {
      skills = match.searchQuery.skills;
    }

    skills = skills.map((skill, index) => ({
      experienceMin: 0,
      experienceMax: 0,
      skillName: skill,
      id: index,
    }));

    console.log(skills);

    if (skills.length > 0) {
      this.setState({
        modal: {
          viewableMatch: match,
          skills: skills,
          show: "skills",
        },
      });
    } else {
      this.setState({
        modal: {
          viewableMatch: match,
          skills: skills,
          show: "noSkills",
        },
      });
    }
  };

  render() {
    return (
      <Container
        style={{
          maxWidth: 1325,
        }}
      >
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <MatchesContainer>
              <MatchesHeadingContainer>
                <MatchesHeadingMenuItem>
                  Matches
                </MatchesHeadingMenuItem>
                {/* <p style={{display: "block", marginLeft: "auto", marginRight: 0, fontWeight: "bold", color: "red", fontSize: 12}}>Powered by GoGig</p> */}
                {/* <MatchesHeadingMenuItem>New Matches</MatchesHeadingMenuItem> */}
              </MatchesHeadingContainer>
              {!this.state.candidateMatches.length > 0 && (
                <NullMatchesContainer>
                  <h1>You don't have any matches right now</h1>
                  <p>
                    Don't worry, head on over to your profile section and make
                    sure that it's up to date and accurate. Cannabis Insider Jobs Talent Community is
                    working hard to get you matched with Hiring Companies based
                    on your desires.
                  </p>
                </NullMatchesContainer>
              )}
              <MatchesListContainer>
                {this.state.candidateMatches.map(
                  (candidateMatch) =>
                    candidateMatch.candidateStatus !== "declined" && (
                      <MatchesListItemContainer>
                        <Avatar
                          class="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                          character={candidateMatch.recruiter.avatar.slug}
                          width={50}
                          height={50}
                        />
                        <MatchesItemDetailsContainer>
                          <MatchesItemName>
                            {candidateMatch.recruiter.firstName +
                              " " +
                              candidateMatch.recruiter.lastName}{" "}
                            at{" "}
                            {candidateMatch.searchQuery.hiringCompany}
                          </MatchesItemName>
                          <MatchesItemJobTitle>
                            Hiring on behalf of{" "}
                            {candidateMatch.searchQuery.hiringCompany}
                          </MatchesItemJobTitle>
                          <MatchesItemLocation>
                            {candidateMatch.searchQuery.cities.map(
                              (city) => " " + city.label + " "
                            )}
                          </MatchesItemLocation>
                        </MatchesItemDetailsContainer>
                        {
                          {
                            pending: (
                              // <div>
                              //   <Button
                              //     onClick={() =>
                              //       this.handleAcceptMatch(candidateMatch.id)
                              //     }
                              //     className="  mr-3"
                              //   >
                              //     Accept
                              //   </Button>
                              //   <Button
                              //     onClick={() =>
                              //       this.handleDeclineMatch(candidateMatch.id)
                              //     }
                              //     className=" "
                              //   >
                              //     Decline
                              //   </Button>
                              // </div>
                              <div>
                                <Button
                                  onClick={() =>
                                    this.viewMatch(candidateMatch.id)
                                  }
                                >
                                  Pending
                                </Button>
                              </div>
                            ),
                            accepted: (
                              <div>
                                <Link to={"/messages/" + candidateMatch.id}>
                                  <Button>View Chat</Button>
                                </Link>
                              </div>
                            ),
                          }[candidateMatch.candidateStatus]
                        }
                      </MatchesListItemContainer>
                    )
                )}
              </MatchesListContainer>
            </MatchesContainer>
          </Col>
        </Row>
        <Modal show={this.state.modal.show === "skills"} size="xl" centered>
          <ModalContainerTwo>
            <ModalCol>
              <img
                className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                style={{
                  width: 280,
                  bottom: 0,
                }}
                src={require("images/modals/man-thinking-tall.png")}
              />
            </ModalCol>
            <ModalCol className="p-5 w-100 bg-white">
              <h1>
                New Match from{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany}
              </h1>
              <p>
                {this.state.modal.viewableMatch.recruiter.firstName} hiring on
                behalf of{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany} wants
                to talk but first, they need to know how well versed you are
                in....
              </p>
              {this.state.modal.skills.map((skill) => (
                <Form
                  style={{
                    marginTop: 75,
                  }}
                >
                  <Form.Row style={{}} className="mt-5">
                    <Col>
                      <Form.Label style={{ marginBottom: 20 }}>
                        {skill.skillName}
                      </Form.Label>
                      <SliderWithTooltip
                        min={0}
                        tipFormatter={(value) => "OK"}
                        max={4}
                        tipProps={{ visible: true }}
                        onChange={(d) =>
                          this.onExperienceSliderChange(skill.id, d)
                        }
                        marks={{
                          0: "Don't Know This Skill",
                          1: "Beginner",
                          2: "Intermediate",
                          3: "Advanced",
                          4: "World Class",
                        }}
                        railStyle={{
                          backgroundColor: "#f5f5f5",
                          height: 10,
                        }}
                        trackStyle={[
                          { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                        ]}
                        handleStyle={[
                          {
                            background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                            borderColor: "rgba(125, 89, 252, 0)",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                          {
                            background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                            borderColor: "rgba(125, 89, 252, 0)",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                        ]}
                        dotStyle={{
                          display: "none",
                        }}
                      />
                    </Col>
                  </Form.Row>
                </Form>
              ))}
              <Form.Row className="mt-5">
                <Col className="">
                  <div className="d-flex justify-content-center">
                    <Button onClick={this.handleAccept} className="mt-3">
                      ACCEPT and Reveal my Identity
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{
                        color: "#47A836",
                        fonSize: 12,
                        textDecoration: "underline",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDecline}
                      className="mt-3"
                      variant="outline-primary"
                    >
                      Decline and Do Not Reveal my Identity
                    </p>
                  </div>
                </Col>
              </Form.Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
        <Modal show={this.state.modal.show === "noSkills"} size="lg" centered>
          <ModalContainerTwo>
            <ModalCol>
              <img
                className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                style={{
                  width: 280,
                  bottom: 0,
                }}
                src={require("images/modals/man-thinking-tall.png")}
              />
            </ModalCol>
            <ModalCol className="p-5 w-100 bg-white">
              <h1>
                New Match from{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany}
              </h1>
              <p>
                {this.state.modal.viewableMatch.recruiter.firstName} hiring on
                behalf of{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany} wants
                to talk but first, they need to know how well versed you are
                in....
              </p>
              <Form.Row className="mt-5">
                <Col className="">
                  <div className="d-flex justify-content-center">
                    <Button onClick={this.handleAccept} className="mt-3">
                      ACCEPT and Reveal my Identity
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{
                        color: "#47A836",
                        fonSize: 12,
                        textDecoration: "underline",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDecline}
                      className="mt-3"
                      variant="outline-primary"
                    >
                      Decline and Do Not Reveal my Identity
                    </p>
                  </div>
                </Col>
              </Form.Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
        <BottomNavigation />
      </Container>
    );
  }
}

const MatchesContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
`;

const MatchesHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const MatchesHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #121a50;
  font-size: 14px;
`;

const MatchesListContainer = styled.div``;
const MatchesListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const MatchesItemImage = styled.img``;
const MatchesItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const MatchesItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const MatchesItemJobTitle = styled.p`
  color: #121A50;
  font-size: 14px;
  margin: 0;
`;
const MatchesItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const NullMatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  h1 {
    color: #999 !important;
  }
  p {
    color: #999;
    font-size: 16px;
  }
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

export default MatchesScreen;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Container,
  Modal,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import styled from "styled-components";
import API from "API";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";
import Select from "react-select";
import BackgroundImg from "images/landing-blob.png";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const officeTypes = ["Remote Work", "Office Work", "Office-first hybrid", "Remote-first hybrid"]

class CandidateOnboarding extends Component {
  state = {
    error: null,
    loading: false,
    data: {},
    onboarding: {
      stepper: {
        currentStep: 0,
        minStep: 0,
        maxStep: 0,
      },
    },
    onboardingComplete: true,
    jobPositions: [],
    jobPositionSpecialties: [],
    jobPositionJobPositionSpecialties: [],
    jobSearchStages: [],
    currentJobPosition: "",
    desiredJobPosition: {},
    jobSearchStage: "",
    currentJobPosition: {},
    employmentEligibilities: [],
    selectedJobPositionSpecialties: [],
    currentAnnualSalary: 0,
    currentAnnualSalaryPrivate: false,
    desiredAnnualSalaryMin: 0,
    desiredAnnualSalaryMax: 0,
    // hbcus: [],
    // hbcuAttendedId: "",
    // hbcuAttended: {},
    desiredSearchDistanceMin: 0,
    desiredSearchDistanceMax: 0,
    userDesiredIndustry: {},
    userDesiredCompanyType: {},
    userDesiredJobLeve: {},
    hiringPartnerListings: false,
    currentLocation: "",
    desiredCities: [],
    desiredStates: [],
    thirdPartyRecruiters: "yes",
    writingSampleTypes: [],
    writingSampleType: "",
    writingSample: "",
    jobLevels: [],
    companyTypeEarly: 0,
    companyTypeLate: 0,
    companyTypeSmall: 0,
    companyTypeMedium: 0,
    companyTypeLarge: 0,
    currentCity: {},
    availableDegrees: [],
    currentDegreeId: "",
    availableCompanyTypes: [],
    userDesiredCompanyTypeId: "",
    currentAnnualSalaryMin: 0,
    currentJobPositionSpecialty: {},
    currentJobPositionJobPositionSpecialties: [],
    industries: [],
    currentJobLevel: {},
    currentIndustry: {},
    employmentEligibilityId: "",
    userDesiredJobLevel: {},
    userPitch: "",
    currentAnnualSalaryPrivate: false,
    userDesiredIndustries: [],
    userDesiredCompanyTypes: [],
    employmentType: {},
    employmentTypes: [],
    errorCurrent: null,
    errorDesires: null,
    errorCulture: null,
    currentRegion: {},
    desiredOfficeTypes: [],
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.modalRef = React.createRef();
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      let { onboardingComplete } = response.data;
      this.setState({
        onboardingComplete,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      // Reorder with a filter so that all Cannabis Insider Jobs Talent Community job positions are at the top of the list
      this.setState({
        jobPositions: response.data
        // jobPositions: [...response.data.filter(pos => pos.type === "V"), ...response.data.filter(pos => pos.type === "G")]
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data,
        });
      }
    );
    API.get("/JobSearchStages").then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });

    API.get("/EmploymentEligibilities").then((response) => {
      this.setState({
        employmentEligibilities: response.data,
      });
    });

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(`/Industries?filter={"order": "name ASC"}`).then((response) => {
      let data = response.data;

      data.some((industry, index) => industry.name == "Cannabis" && industry.type == "C" && data.unshift(data.splice(index, 1)[0]));

      this.setState({
        industries: data,
      });
    });

    API.get("/WritingSampleTypes").then((response) => {
      this.setState({
        writingSampleTypes: response.data,
      });
    });
    API.get(`/Degrees?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        availableDegrees: response.data,
      });
    });

    // API.get(`/HBCUAttendeds?filter={"order": "name ASC"}`).then((response) => {
    //   this.setState({
    //     hbcus: response.data,
    //   });
    // });

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          availableCompanyTypes: response.data,
        });
      }
    );

    API.get(`/EmploymentTypes`).then((response) => {
      this.setState({
        employmentTypes: response.data,
      });
    });
  }

  incrementStep = () => {
    this.myRef.current.scrollIntoView();
    if (this.state.onboarding.stepper.currentStep === 2) {
      if (
        this.state.currentJobPosition.id === "" ||
        this.state.currentAnnualSalaryMin === "" ||
        this.state.currentAnnualSalaryMax === "" ||
        this.state.currentJobLevel.id === "" ||
        this.state.currentIndustry.id === "" ||
        this.state.currentCity === "" ||
        this.state.jobSearchStage === "" ||
        this.state.currentDegreeId === "" ||
        this.state.currentJobPositionSpecialty.id === "" ||
        this.state.employmentType.id === ""
      ) {
        this.setState({
          errorCurrent: true,
        });
      } else {
        this.setState({
          errorCurrent: null,
          onboarding: {
            ...this.state.onboarding,
            stepper: {
              ...this.state.onboarding.stepper,
              currentStep: this.state.onboarding.stepper.currentStep + 1,
            },
          },
        });
      }
    } else if (this.state.onboarding.stepper.currentStep === 3) {
      if (
        this.state.userDesiredIndustry.id === "" ||
        this.state.userDesiredJobLevel.id === "" ||
        this.state.desiredAnnualSalaryMin === "" ||
        this.state.desiredAnnualSalaryMax === "" ||
        this.state.desiredSearchDistanceMax === "" ||
        this.state.desiredSearchDistanceMin === "" ||
        this.state.desiredCities === "" ||
        this.state.userPitch === "" ||
        this.state.desiredOfficeTypes.length === 0
      ) {
        this.setState({
          errorDesires: true,
        });
      } else {
        this.setState({
          errorDesires: null,
          onboarding: {
            ...this.state.onboarding,
            stepper: {
              ...this.state.onboarding.stepper,
              currentStep: this.state.onboarding.stepper.currentStep + 1,
            },
          },
        });
      }
    } else {
      this.setState({
        onboarding: {
          ...this.state.onboarding,
          stepper: {
            ...this.state.onboarding.stepper,
            currentStep: this.state.onboarding.stepper.currentStep + 1,
          },
        },
      });
    }
    const modalDOM = ReactDOM.findDOMNode(this.modal);
    modalDOM.scrollTop = 0;
  };

  decrementStep = () => {
    this.setState({
      onboarding: {
        ...this.state.onboarding,
        stepper: {
          ...this.state.onboarding.stepper,
          currentStep: this.state.onboarding.stepper.currentStep - 1,
        },
      },
    });
  };

  screamChange = (v) => {
    if (
      this.state.selectedJobPositionSpecialties.find(
        (jobPositionSpecialty) => jobPositionSpecialty.id === v[0].value.id
      ) === undefined
    ) {
      if (v.length <= 5) {
        let selectedSpecialties = v.map((selectedSpecialty) => {
          return {
            ...selectedSpecialty.value,
            jobPositionSpecialty: {
              ...selectedSpecialty.value,
            },
            experience:
              "experience" in selectedSpecialty.value
                ? selectedSpecialty.value.experience
                : 0,
          };
        });

        console.log(selectedSpecialties);

        this.setState({
          selectedJobPositionSpecialties:
            this.state.selectedJobPositionSpecialties.concat(
              selectedSpecialties
            ),
          desiredJobPosition: {},
        });
      }
    }
  };

  screamChangeSingle = (v, attr) => {
    this.setState({
      [attr]: v.value,
      currentJobPositionSpecialty: null,
    });
    let jobPosition = v.value;
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    console.log(jobPosition);
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      currentJobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      currentJobPosition: jobPosition,
    });
  };

  screamChangeSingleDesires = (v, attr) => {
    this.setState({
      [attr]: v.value,
    });
    let jobPosition = v.value;
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      desiredJobPosition: jobPosition,
    });
  };

  screamChangeSingleTwo = (v, attr) => {
    this.setState({
      [attr]: v.value,
    });
  };

  handleMultiSelect = (v, attr) => {
    if (v === null) {
      this.setState({
        [attr]: [],
      });
    } else {
      this.setState({
        [attr]: v,
      });
    }
  };

  handleDesiredJobPosition = (e) => {
    let { name, value } = e.target;
    let jobPosition = JSON.parse(value);
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      jobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      desiredJobPosition: jobPosition,
    });
  };

  handleCurrentJobPosition = (e) => {
    let { name, value } = e.target;
    let jobPosition = JSON.parse(value);
    let jobPositionSpecialties = this.state.jobPositionSpecialties;
    let filteredJobPositionSpecialties = jobPositionSpecialties.filter(
      (jobPositionSpecialty) => {
        return jobPosition.id === jobPositionSpecialty.jobPositionId;
      }
    );
    this.setState({
      currentJobPositionJobPositionSpecialties: filteredJobPositionSpecialties,
      currentJobPosition: jobPosition,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmit = (e) => {
    /*
      Make API Requests here!
    */

    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      currentJobPositionId: this.state.currentJobPosition.id,
      currentAnnualSalaryMin: this.state.currentAnnualSalaryMin,
      currentAnnualSalaryMax: this.state.currentAnnualSalaryMax,
      currentJobLevelId: this.state.currentJobLevel.id,
      currentIndustryId: this.state.currentIndustry.id,
      thirdPartyRecruiters:
        this.state.thirdPartyRecruiters === "yes" ? true : false,
      currentAnnualSalaryPrivate: this.state.currentAnnualSalaryPrivate,
      currentJobLevel: this.state.currentAnnualSalaryPrivate,
      desiredAnnualSalaryMin: this.state.desiredAnnualSalaryMin,
      desiredAnnualSalaryMax: this.state.desiredAnnualSalaryMax,
      onboardingComplete: true,
      desiredOfficeTypes: this.state.desiredOfficeTypes,
      desiredSearchDistanceMax: this.state.desiredSearchDistanceMax,
      desiredSearchDistanceMin: this.state.desiredSearchDistanceMin,
      desiredCities: this.state.desiredCities,
      desiredStates: this.state.desiredStates,
      currentCity: this.state.currentCity,
      companyTypeEarly: this.state.companyTypeEarly,
      companyTypeLarge: this.state.companyTypeLarge,
      companyTypeSmall: this.state.companyTypeSmall,
      companyTypeMedium: this.state.companyTypeMedium,
      companyTypeLate: this.state.companyTypeLate,
      jobSearchStageId: this.state.jobSearchStage,
      currentDegreeId: this.state.currentDegreeId,
      currentJobPositionSpecialtyId: this.state.currentJobPositionSpecialty.id,
      userDesiredCompanyTypeId: this.state.userDesiredCompanyTypeId,
      userDesiredIndustryId: this.state.userDesiredIndustry.id,
      userDesiredJobLevelId: this.state.userDesiredJobLevel.id,
      userPitch: this.state.userPitch,
      currentAnnualSalaryPrivate: this.state.currentAnnualSalaryPrivate,
      employmentTypeId: this.state.employmentType.id,
      employmentEligibilityId: this.state.employmentEligibilityId,
      currentRegion: this.state.currentRegion
      // hbcuAttendedId: this.state.hbcuAttendedId,

    });

    this.state.selectedJobPositionSpecialties.map((jobPositionSpecialty) => {
      API.post(
        `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositionSpecialties`,
        {
          jobPositionSpecialtyId: jobPositionSpecialty.id,
          experience: jobPositionSpecialty.experience,
        }
      );
      API.put(
        `/UserProfiles/${this.props.userToken.userId}/userDesiredJobPositions/rel/${jobPositionSpecialty.jobPositionId}`
      );
    });

    this.state.userDesiredIndustries.map((industry) => {
      let ind = industry.value;
      API.put(
        `/UserProfiles/${this.props.userToken.userId}/userDesiredIndustries/rel/${ind.id}`,
        {
          name: ind.name,
          slug: ind.slug,
        }
      );
    });

    this.state.userDesiredCompanyTypes.map((companyType) => {
      let ct = companyType.value;
      API.put(
        `/UserProfiles/${this.props.userToken.userId}/userDesiredCompanyTypes/rel/${ct.id}`,
        {
          name: ct.name,
          slug: ct.slug,
        }
      );
    });

    API.post(`/UserWritingSamples`, {
      writingSample: this.state.writingSample,
      writingSampleTypeId: this.state.writingSampleType,
      userProfileId: this.props.userToken.userId,
    }).then((response) => {
      let writingSampleId = response.data.id;
      API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        userWritingSampleId: writingSampleId,
      }).then(() => {
        API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
            "include": {"candidateMatches": ["searchQuery", "candidate", "recruiter", "skills"] }
          }`
        ).then((response) => {
          this.setState(response.data);
          this.props.updateShowPersonality();
          this.props.updateUpdate();
          this.handleSendVerificationEmail();
          window.location.reload(false);
        });
      });
    });


  };

  handleDropdownFilter = (data, filterv) => {
    const result = data.filter((item) => {
      return item.type === filterv || item.type === 'M'
    }).map((item) => {
      return {
        label: item.name,
        value: item,
      };
    })
    return result;
  };

  handleAddJobPositionSpecialty = (e) => {
    let { name, value } = e.target;
    let jobPositionSpecialty = JSON.parse(value);
    jobPositionSpecialty.experience = 0;
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.concat(jobPositionSpecialty),
    });
  };

  handleSendVerificationEmail = () => {
    API.post(`/UserEmailVerificationCodes`, {
      userProfileId: this.state.id,
      email: this.state.email,
    });
  };

  handleRemoveSelectedJobPositionSpecialty = (jobPositionSpecialtyId) => {
    this.setState({
      selectedJobPositionSpecialties:
        this.state.selectedJobPositionSpecialties.filter(
          (jobPositionSpecialty) =>
            jobPositionSpecialty.id !== jobPositionSpecialtyId
        ),
    });
  };

  onSliderChange = (name, value) => {
    if (name == "desiredAnnualSalary") {
      this.setState({
        desiredAnnualSalaryMin: value,
      });
    }

    if (name == "currentAnnualSalary") {
      this.setState({
        currentAnnualSalaryMin: value,
      });
    }

    if (name == "desiredSearchDistance") {
      this.setState({
        desiredSearchDistanceMin: value,
      });
    }
  };

  onExperienceSliderChange = (sliderId, sliderValue) => {
    let selectedJobPositionSpecialties =
      this.state.selectedJobPositionSpecialties;
    let updatedJobPositionSpecialties = selectedJobPositionSpecialties.map(
      (jobPositionSpecialty) => {
        if (sliderId === jobPositionSpecialty.id) {
          jobPositionSpecialty.experience = sliderValue;
        }
        return jobPositionSpecialty;
      }
    );
    this.setState({
      selectedJobPositionSpecialties: updatedJobPositionSpecialties,
    });
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;
    this.setState({
      [name]: inputVals === null ? [] : inputVals,
    });
  };

  render() {
    return (
      <Modal
        show={!this.state.onboardingComplete}
        dialogClassName="modal-65w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        ref={(el) => (this.modal = el)}
      >
        <div ref={this.myRef} />
        {
          {
            0: (
              <Container
                style={{
                  zIndex: 10293842093,
                  backgroundImage: `url(${BackgroundImg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                className="p-5"
              >
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/cji-logo.png")}
                  />
                  <img
                    className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                    style={{
                      position: "absolute",
                      bottom: 350,
                    }}
                    src={require("images/modals/blk-man-think.png")}
                  />
                  <div
                    className="d-md-none d-lg-block d-md-block d-none d-sm-block"
                    style={{
                      height: 350,
                    }}
                  />
                  <h1>Welcome to Cannabis Insider Jobs Talent Community</h1>
                  <p style={{ maxWidth: "600px" }}>
                    Every data point you input into Cannabis Insider Jobs Talent Community is anonymous, so nothing can be
                    Googled or tied back to your identity. You get to choose who
                    you want to reveal your identity to.
                  </p>
                </ModalContainer>

                <Row className="float-right">
                  <Col xs={12}>
                    <Button className=" " onClick={this.incrementStep}>
                      Next
                    </Button>
                  </Col>
                </Row>
              </Container>
            ),
            1: (
              <Container
                style={{
                  backgroundImage: `url(${BackgroundImg})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                className="p-5"
              >
                <img
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: 175,
                    borderRadius: 8,
                    left: 30,
                    top: 30,
                  }}
                  src={require("images/logo/cji-logo.png")}
                />
                <ModalContainer>
                  <img
                    className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                    style={{
                      position: "absolute",
                      bottom: 350,
                    }}
                    src={require("images/modals/blk-man-think.png")}
                  />
                  <div
                    className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                    style={{
                      height: 350,
                    }}
                  />
                  <h1>Passive Job Seeker</h1>
                  <p
                    style={{
                      maxWidth: "600px",
                    }}
                  >
                    Whether you have a job or not, Cannabis Insider Jobs Talent Community
                    is built for you! Don't expect to see any job
                    postings here. Once your profile is complete, keep your
                    notification settings on so you can respond to any company
                    matches you get. Otherwise, the platform stays quiet.
                  </p>
                </ModalContainer>

                <Row className="float-right">
                  <Col xs={12}>
                    <Button className=" " onClick={this.incrementStep}>
                      Next
                    </Button>
                  </Col>
                </Row>
              </Container>
            ),
            2: (
              <ModalContainerTwo>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: 175,
                    borderRadius: 8,
                    left: 30,
                    top: 30,
                  }}
                  src={require("images/logo/cji-logo.png")}
                />
                <ModalCol className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                  <img
                    style={{
                      width: 450,
                      bottom: 0,
                    }}
                    src={require("images/modals/gogig-career-desires.png")}
                  />
                </ModalCol>
                <ModalCol className="p-5 w-100 bg-white">
                  <Row>
                    <h1
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      Your Current Career Experiences
                    </h1>

                    <p style={{
                      fontFamily: "Montserrat",
                      fontSize: 18, fontWeight: "bold"
                    }}>Tell us where you are.</p>
                  </Row>
                  {this.state.errorCurrent !== null && (
                    <Alert variant="danger">
                      Please fill out all the fields below before continuing.
                    </Alert>
                  )}
                  <Form>
                    <Form.Row>
                      <Col>
                        <Form.Label>
                          Current (or most recent) Position Type
                        </Form.Label>
                        <Select
                          onChange={(v) =>
                            this.screamChangeSingle(v, "currentJobPosition")
                          }
                          styles={{
                            container: (base, state) => ({
                              ...base,
                              width: "100%",
                              outline: "none",
                              padding: 0,
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              padding: 0,
                            }),
                            control: (base, state) => ({
                              ...base,
                              border: "none",
                              borderBottom: "1px solid silver",
                              outline: "none",
                              padding: 0,
                              margin: 0,
                              borderRadius: 0,
                              outline: "none",
                            }),
                          }}
                          placeholder="Enter your current (or most recent) Position Type"
                          value={
                            this.state.currentJobPosition.name
                              ? {
                                label: this.state.currentJobPosition.name,
                                value: this.state.currentJobPosition,
                              }
                              : null
                          }
                          isMulti={false}
                          options={this.state.jobPositions.map(
                            (jobPosition) => {
                              return {
                                label: jobPosition.name,
                                value: jobPosition,
                              };
                            }
                          )}
                        />
                      </Col>
                    </Form.Row>

                    {Object.keys(this.state.currentJobPosition).length !==
                      0 && (
                        <Form.Row className="mt-4">
                          <Col>
                            <Form.Label>
                              What specialty within "
                              {this.state.currentJobPosition.name}" are you
                              working (did you previously work) in?
                            </Form.Label>
                            {/* <Form.Control
                            as="select"
                            name="currentJobPositionSpecialty"
                            onChange={this.handleChange}
                            value={JSON.stringify(
                              this.state.currentJobPositionSpecialty
                            )}
                          >
                            {this.state.currentJobPositionJobPositionSpecialties.map(
                              jobPositionSpecialty => (
                                <option
                                  value={JSON.stringify(jobPositionSpecialty)}
                                >
                                  {jobPositionSpecialty.name}
                                </option>
                              )
                            )}
                          </Form.Control> */}
                            <Select
                              styles={{
                                container: (base, state) => ({
                                  ...base,
                                  width: "100%",
                                }),
                                valueContainer: (base, state) => ({
                                  ...base,
                                  padding: 0,
                                }),
                                control: (base, state) => ({
                                  ...base,
                                  border: "none",
                                  borderBottom: "1px solid silver",
                                  outline: "none",
                                  padding: 0,
                                  margin: 0,
                                  borderRadius: 0,
                                }),
                              }}
                              placeholder="Enter your current (or most recent) position specialty"
                              onChange={(v) =>
                                this.screamChangeSingleTwo(
                                  v,
                                  "currentJobPositionSpecialty"
                                )
                              }
                              value={
                                this.state.currentJobPositionSpecialty && {
                                  label:
                                    this.state.currentJobPositionSpecialty.name,
                                  value: this.state.currentJobPositionSpecialty,
                                }
                              }
                              isMulti={false}
                              options={this.state.currentJobPositionJobPositionSpecialties.map(
                                (jobPositionSpecialty) => {
                                  return {
                                    label: jobPositionSpecialty.name,
                                    value: jobPositionSpecialty,
                                  };
                                }
                              )}
                            />
                          </Col>
                        </Form.Row>
                      )}

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          Current (or most recent) industry you worked in
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="currentIndustry"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.currentIndustry)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Enter your current (or most recent) industry
                          </option>
                          {this.state.industries.map((industry) => (
                            <option value={JSON.stringify(industry)}>
                              {industry.name}
                            </option>
                          ))}

                        </Form.Control>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          Current (or most recent) Job Level
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="currentJobLevel"
                          value={JSON.stringify(this.state.currentJobLevel)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Enter your current (or most recent) Job Level
                          </option>
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>
                          What is the most recent total annual compensation you
                          earned from your last (or current) job?
                        </Form.Label>
                        {!this.state.currentAnnualSalaryPrivate && (
                          <div style={{ width: "100%" }}>
                            <SliderValueContainer>
                              <SliderValue style={{ textAlign: "end" }}>
                                {this.state.currentAnnualSalaryMin === 500000
                                  ? "$" +
                                  this.state.currentAnnualSalaryMin.toLocaleString() +
                                  "+"
                                  : "$" +
                                  this.state.currentAnnualSalaryMin.toLocaleString()}
                              </SliderValue>
                            </SliderValueContainer>
                            <Slider
                              min={0}
                              max={500000}
                              step={5000}
                              value={this.state.currentAnnualSalaryMin}
                              onChange={(d) =>
                                this.onSliderChange("currentAnnualSalary", d)
                              }
                              marks={{
                                0: "$0",
                                500000: "$500,000+",
                              }}
                              defaultValue={0}
                              allowCross={false}
                              tipFormatter={(value) => `${value}`}
                              railStyle={{
                                backgroundColor: "#f5f5f5",
                                height: 10,
                              }}
                              trackStyle={[
                                { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                                { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                              ]}
                              handleStyle={[
                                {
                                  background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                  borderColor: "rgba(39, 129, 52, 1)",
                                  width: 17,
                                  height: 17,
                                  top: 6,
                                },
                                {
                                  background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                  borderColor: "rgba(39, 129, 52, 1)",
                                  width: 17,
                                  height: 17,
                                  top: 6,
                                },
                              ]}
                              dotStyle={{
                                display: "none",
                              }}
                            />
                          </div>
                        )}
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            name="currentAnnualSalaryPrivate"
                            onChange={() =>
                              this.setState({
                                currentAnnualSalaryPrivate:
                                  !this.state.currentAnnualSalaryPrivate,
                              })
                            }
                            type="checkbox"
                            checked={this.state.currentAnnualSalaryPrivate}
                            label="Prefer not to say"
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-2">
                      <Col>
                        <Form.Label>Work Experience</Form.Label>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-1">
                      <Col>
                        <div>
                          <p style={{ margin: 0, color: "#000", padding: 0 }}>
                            Early Stage Startup
                          </p>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "#999",
                              fontSize: 15,
                            }}
                          >
                            You wear multiple hats to help the company get off
                            the ground
                          </p>
                        </div>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.companyTypeEarly === 25
                              ? "25+"
                              : this.state.companyTypeEarly}{" "}
                            {this.state.companyTypeEarly === 1
                              ? "year"
                              : "years"}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={25}
                          onChange={(v) =>
                            this.setState({
                              companyTypeEarly: v,
                            })
                          }
                          marks={{
                            0: "0",
                            25: "25 years+",
                          }}
                          value={this.state.companyTypeEarly}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `{value} years`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <div>
                          <p style={{ margin: 0, color: "#000", padding: 0 }}>
                            Later Stage Startup
                          </p>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "#999",
                              fontSize: 15,
                            }}
                          >
                            You refine the processes and/or product to help the
                            company scale
                          </p>
                        </div>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.companyTypeLate === 25
                              ? "25+"
                              : this.state.companyTypeLate}{" "}
                            {this.state.companyTypeLate === 1
                              ? "year"
                              : "years"}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={25}
                          onChange={(v) =>
                            this.setState({
                              companyTypeLate: v,
                            })
                          }
                          marks={{
                            0: "0",
                            25: "25 years+",
                          }}
                          value={this.state.companyTypeLate}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `{value} years`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <div>
                          <p style={{ margin: 0, color: "#000", padding: 0 }}>
                            Small (0-50 Employees)
                          </p>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "#999",
                              fontSize: 15,
                            }}
                          >
                            You support an established enterprise with focus on
                            retention and opportunity
                          </p>
                        </div>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.companyTypeSmall === 25
                              ? "25+"
                              : this.state.companyTypeSmall}{" "}
                            {this.state.companyTypeSmall === 1
                              ? "year"
                              : "years"}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={25}
                          onChange={(v) =>
                            this.setState({
                              companyTypeSmall: v,
                            })
                          }
                          value={this.state.companyTypeSmall}
                          marks={{
                            0: "0",
                            25: "25 years+",
                          }}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `{value} years`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <div>
                          <p style={{ margin: 0, color: "#000", padding: 0 }}>
                            Medium (51-200 Employees)
                          </p>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "#999",
                              fontSize: 15,
                            }}
                          >
                            You support a mid-size enterprise with focus to grow
                            or maintain market share
                          </p>
                        </div>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.companyTypeMedium === 25
                              ? "25+"
                              : this.state.companyTypeMedium}{" "}
                            {this.state.companyTypeMedium === 1
                              ? "year"
                              : "years"}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={25}
                          onChange={(v) =>
                            this.setState({
                              companyTypeMedium: v,
                            })
                          }
                          marks={{
                            0: "0",
                            25: "25 years+",
                          }}
                          value={this.state.companyTypeMedium}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `{value} years`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <div>
                          <p style={{ margin: 0, color: "#000", padding: 0 }}>
                            Large (201+ Employees)
                          </p>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "#999",
                              fontSize: 15,
                            }}
                          >
                            You support a large enterprise with focus to turn
                            new business models into revenue generating
                            opportunties
                          </p>
                        </div>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.companyTypeLarge === 25
                              ? "25+"
                              : this.state.companyTypeLarge}{" "}
                            {this.state.companyTypeLarge === 1
                              ? "year"
                              : "years"}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={25}
                          onChange={(v) =>
                            this.setState({
                              companyTypeLarge: v,
                            })
                          }
                          marks={{
                            0: "0",
                            25: "25 years+",
                          }}
                          value={this.state.companyTypeLarge}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `{value} years`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-4">
                          What best describes where you're at in your job
                          search?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="jobSearchStage"
                          onChange={this.handleChange}
                          value={this.state.jobSearchStage}
                        >
                          <option disabled value="">
                            Where are you currently in your job search?
                          </option>
                          {this.state.jobSearchStages.map((jobSearchStage) => (
                            <option value={jobSearchStage.id}>
                              {jobSearchStage.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    {/* <Form.Row>
                      <Form.Label className="mt-4">
                        What states would you like to work in?
                      </Form.Label>
                      <GeoSelectState
                        isMulti={true}
                        name="desiredStates"
                        handleChange={this.handleSelectChange}
                      />
                    </Form.Row> */}

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-4">
                          Where is your current home location?
                        </Form.Label>
                        {/* <GeoSelectRegion
                          placeholder="Enter up to 4 Cities and States"
                          isMulti={false}
                          name="currentRegion"
                          value={
                            Object.keys(this.state.currentRegion).length === 0
                              ? null
                              : this.state.currentRegion
                          }
                          handleChange={this.handleSelectChange}
                        /> */}
                        <GeoSelectCity
                          isMulti={false}
                          name="currentCity"
                          value={
                            Object.keys(this.state.currentCity).length === 0
                              ? null
                              : this.state.currentCity
                          }
                          handleChange={this.handleSelectChange}
                          placeholder="Enter one City and State"
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-4">
                          Highest level of education obtained
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="currentDegreeId"
                          onChange={this.handleChange}
                          value={this.state.currentDegreeId}
                        >
                          <option disabled value="">
                            Select highest level of education obtained
                          </option>
                          {this.state.availableDegrees.map((degree) => (
                            <option value={degree.id}>{degree.name}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>



                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-4">
                          Employment Eligibility
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentEligibilityId"
                          onChange={this.handleChange}
                          value={this.state.employmentEligibilityId}
                        >
                          <option disabled value="">
                            Select employment status
                          </option>
                          {this.state.employmentEligibilities.map((ee) => (
                            <option value={ee.id}>{ee.title}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Button
                        variant="outline-primary"
                        onClick={this.decrementStep}
                      >
                        Back
                      </Button>
                      <Button className="ml-auto" onClick={this.incrementStep}>
                        Next
                      </Button>
                    </Form.Row>
                  </Form>
                </ModalCol>
              </ModalContainerTwo>
            ),
            3: (
              <ModalContainerTwo>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: 175,
                    borderRadius: 8,
                    left: 30,
                    top: 30,
                  }}
                  src={require("images/logo/cji-logo.png")}
                />
                <ModalCol className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                  <img
                    style={{
                      width: 450,
                      bottom: 0,
                    }}
                    src={require("images/modals/gogig-career-desires.png")}
                  />
                </ModalCol>
                <ModalCol className="p-5 w-100 bg-white">
                  <Row>
                    <Col>
                      <h1 style={{ fontWeight: "normal" }}>
                        Your Career Desires
                      </h1>
                      <p style={{
                        fontFamily: "Montserrat",
                        fontSize: 20, fontWeight: "bold"
                      }}>
                        Tell us where you want to be, so we can help you get
                        there!
                      </p>
                    </Col>
                  </Row>
                  {this.state.errorDesires !== null && (
                    <Alert variant="danger">
                      Please fill out all the fields below before continuing.
                    </Alert>
                  )}
                  <Form>
                    <Form.Row>
                      {this.state.selectedJobPositionSpecialties.map(
                        (jobPositionSpecialty) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "rgb(196, 207, 213)",
                              fontWeight: "bold",
                              padding: 10,
                              borderRadius: 100,
                              margin: 5,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 13,
                                margin: 0,
                              }}
                            >
                              {jobPositionSpecialty.jobPosition.name}:{" "}
                              {jobPositionSpecialty.name}
                            </p>
                            <p
                              style={{
                                fontSize: 13,
                                margin: 0,
                                marginLeft: 10,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.handleRemoveSelectedJobPositionSpecialty(
                                  jobPositionSpecialty.id
                                )
                              }
                            >
                              X
                            </p>
                          </div>
                        )
                      )}
                    </Form.Row>
                    <Form.Row className="mt-2">
                      <Col>
                        <div>
                          <p
                            style={{
                              margin: 0,
                              color: "#000",
                              padding: 0,
                              fontWeight: 500,
                            }}
                          >
                            Desired position type for your next career move
                          </p>
                          {this.state.selectedJobPositionSpecialties.length >=
                            3 ? (
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "#A5BBC2",
                                fontSize: 15,
                              }}
                            >
                              <i>
                                You can only choose up to three combinations of
                                position types and specialties. Please adjust
                                your choices above.
                              </i>
                            </p>
                          ) : (
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "#999",
                                fontSize: 15,
                              }}
                            >
                              Choose up to three combinations of position types
                              and specialties
                            </p>
                          )}
                        </div>
                        {/* <Form.Control
                        as="select"
                        name="desiredJobPosition"
                        onChange={this.handleDesiredJobPosition}
                        value={JSON.stringify(this.state.desiredJobPosition)}
                      >
                        <option value={JSON.stringify({})} disabled>
                          Desired Position Type
                        </option>
                        {this.state.jobPositions.map(jobPosition => (
                          <option value={JSON.stringify(jobPosition)}>
                            {jobPosition.name}
                          </option>
                        ))}
                      </Form.Control> */}
                        {this.state.selectedJobPositionSpecialties.length <
                          3 && (
                            <Select
                              onChange={(v) =>
                                this.screamChangeSingleDesires(
                                  v,
                                  "desiredJobPosition"
                                )
                              }
                              styles={{
                                container: (base, state) => ({
                                  ...base,
                                  width: "100%",
                                  outline: "none",
                                }),
                                control: (base, state) => ({
                                  ...base,
                                  border: "none",
                                  borderBottom: "1px solid silver",
                                  outline: "none",
                                  padding: 0,
                                  margin: 0,
                                  borderRadius: 0,
                                  outline: "none",
                                }),
                              }}
                              value={
                                JSON.stringify(this.state.desiredJobPosition) ===
                                  JSON.stringify({})
                                  ? null
                                  : {
                                    label: this.state.desiredJobPosition.name,
                                    value: this.state.desiredJobPosition,
                                  }
                              }
                              // value={null}
                              placeholder="Select Desired Position Type"
                              defaultValue={null}
                              isMulti={false}
                              options={this.state.jobPositions.filter((job) => job.type !== "G").map(
                                (jobPosition) => {
                                  return {
                                    label: jobPosition.name,
                                    value: jobPosition,
                                  };
                                }
                              )}
                            />
                          )}
                      </Col>
                    </Form.Row>

                    {this.state.selectedJobPositionSpecialties.length < 3 &&
                      Object.keys(this.state.desiredJobPosition).length !==
                      0 && (
                        <Form.Row className="mt-4">
                          <Col>
                            <Form.Label>
                              What specialty within{" "}
                              {this.state.desiredJobPosition.name} are you
                              interested in?
                            </Form.Label>
                            <Select
                              onChange={this.screamChange}
                              styles={{
                                container: (base, state) => ({
                                  ...base,
                                  width: "100%",
                                  outline: "none",
                                }),
                                valueContainer: (base, state) => ({
                                  ...base,
                                  padding: 0,
                                }),
                                control: (base, state) => ({
                                  ...base,
                                  border: "none",
                                  borderBottom: "1px solid silver",
                                  outline: "none",
                                  padding: 0,
                                  margin: 0,
                                  borderRadius: 0,
                                  outline: "none",
                                }),
                                multiValue: (base, state) => ({
                                  ...base,
                                  borderRadius: 30,
                                  backgroundColor: "rgb(196, 207, 213)",
                                  fontWeight: "bold",
                                  marginRight: 10,
                                  marginBottom: 10,
                                }),
                              }}
                              placeholder="Select Specialty"
                              value={[]}
                              isMulti={true}
                              options={this.state.jobPositionJobPositionSpecialties.filter((specialty) => specialty.type !== "G").map(
                                (jobPositionSpecialty) => {
                                  return {
                                    label: jobPositionSpecialty.name,
                                    value: jobPositionSpecialty,
                                  };
                                }
                              )}
                            />
                          </Col>
                        </Form.Row>
                      )}

                    {this.state.selectedJobPositionSpecialties.length > 0 && (
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>
                            Years of experience in these specialties:
                          </Form.Label>
                        </Col>
                      </Form.Row>
                    )}

                    {this.state.selectedJobPositionSpecialties.map(
                      (jobPositionSpecialty, index) => {
                        let sliderTitleText = jobPositionSpecialty.jobPositionSpecialty.jobPosition.name + ": " + jobPositionSpecialty.jobPositionSpecialty.name
                        return (
                          <Form.Row className={index == 0 ? "mt-2" : "mt-5"}>
                            <Col>
                              <Form.Label style={sliderTitleText.length >= 50 && sliderTitleText.length <= 56 ? { marginBottom: 30 } : {}}>
                                {sliderTitleText}
                              </Form.Label>

                              <SliderValueContainer
                                style={
                                  sliderTitleText.length >= 50 ? { bottom: 25, position: "relative", display: "inline-block", float: "right" } : {display: "inline-block", float: "right"}
                                }>
                                <SliderValue>
                                  {jobPositionSpecialty.experience} years
                                </SliderValue>
                              </SliderValueContainer>

                              <Slider
                                defaultValue={0}
                                min={0}
                                max={10}
                                value={jobPositionSpecialty.experience}
                                onChange={(d) =>
                                  this.onExperienceSliderChange(
                                    jobPositionSpecialty.id,
                                    d
                                  )
                                }
                                marks={{
                                  0: "0",
                                  10: "10+ Years",
                                }}
                                allowCross={false}
                                tipFormatter={(value) => `${value}`}
                                railStyle={{
                                  backgroundColor: "#f5f5f5",
                                  height: 10,
                                }}
                                trackStyle={[
                                  { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                                  { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                                ]}
                                handleStyle={[
                                  {
                                    background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                    borderColor: "rgba(39, 129, 52, 1)",
                                    width: 17,
                                    height: 17,
                                    top: 6,
                                  },
                                  {
                                    background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                    borderColor: "rgba(39, 129, 52, 1)",
                                    width: 17,
                                    height: 17,
                                    top: 6,
                                  },
                                ]}
                                dotStyle={{
                                  display: "none",
                                }}
                              />
                            </Col>
                          </Form.Row>
                        )
                      }
                    )}

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>
                          Desired job level for your next career move
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="userDesiredJobLevel"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.userDesiredJobLevel)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Desired Job Level
                          </option>{" "}
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    {/* <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          How much total annual compensation are you targeting
                          from your next career?
                        </Form.Label>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.desiredAnnualSalaryMin === 500000
                              ? "$" +
                                this.state.desiredAnnualSalaryMin.toLocaleString() +
                                "+"
                              : "$" +
                                this.state.desiredAnnualSalaryMin.toLocaleString()}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={500000}
                          step={5000}
                          onChange={(d) =>
                            this.onSliderChange("desiredAnnualSalary", d)
                          }
                          value={this.state.desiredAnnualSalaryMin}
                          marks={{
                            0: "0",
                            500000: "$500,000+",
                          }}
                          defaultValue={0}
                          allowCross={false}
                          tipFormatter={(value) => `${value}`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { backgroundColor: "#040404", height: 10 },
                            { backgroundColor: "#040404", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              backgroundColor: "#040404",
                              borderColor: "#040404",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              backgroundColor: "#040404",
                              borderColor: "#040404",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row> */}

                    {/* <Form.Row>
                      <Form.Label className="mt-5">
                        What states would you like to work in?
                      </Form.Label>
                      <GeoSelectState
                        isMulti={true}
                        name="desiredStates"
                        handleChange={this.handleSelectChange}
                      />
                    </Form.Row> */}

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>
                          What industry do you want to be working in?
                        </Form.Label>
                        <Select
                          onChange={(v) =>
                            this.handleMultiSelect(v, "userDesiredIndustries")
                          }
                          value={this.state.userDesiredIndustries}
                          styles={{
                            container: (base, state) => ({
                              ...base,
                              width: "100%",
                              outline: "none",
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              padding: 0,
                            }),
                            control: (base, state) => ({
                              ...base,
                              border: "none",
                              borderBottom: "1px solid silver",
                              outline: "none",
                              padding: 0,
                              margin: 0,
                              borderRadius: 0,
                              outline: "none",
                            }),
                            multiValue: (base, state) => ({
                              ...base,
                              borderRadius: 30,
                              backgroundColor: "rgb(196, 207, 213)",
                              fontWeight: "bold",
                              marginRight: 10,
                              marginBottom: 10,
                            }),
                          }}
                          placeholder="Select your desired industry"
                          isMulti={true}
                          options={
                            this.state.userDesiredIndustries.length >= 1
                              ? []
                              : this.handleDropdownFilter(this.state.industries, "C")
                          }
                        />
                        {this.state.userDesiredIndustries.length >= 3 && (
                          <p
                            style={{
                              marginTop: 40,
                              color: "#A5BBC2",
                            }}
                          >
                            <i>
                              You can only choose up to 3 desired industries.
                              Please remove one to adjust your selection.
                            </i>
                          </p>
                        )}
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-5">
                          Desired company type for your next career move
                        </Form.Label>
                        <Select
                          onChange={(v) =>
                            this.handleMultiSelect(v, "userDesiredCompanyTypes")
                          }
                          styles={{
                            container: (base, state) => ({
                              ...base,
                              width: "100%",
                              outline: "none",
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              padding: 0,
                            }),
                            control: (base, state) => ({
                              ...base,
                              border: "none",
                              borderBottom: "1px solid silver",
                              outline: "none",
                              padding: 0,
                              margin: 0,
                              borderRadius: 0,
                              outline: "none",
                            }),
                            multiValue: (base, state) => ({
                              ...base,
                              borderRadius: 30,
                              backgroundColor: "rgb(196, 207, 213)",
                              fontWeight: "bold",
                              marginRight: 10,
                              marginBottom: 10,
                            }),
                          }}
                          value={this.state.userDesiredCompanyTypes}
                          placeholder="Desired Company Type(s)"
                          isMulti={true}
                          options={this.state.availableCompanyTypes.map(
                            (ct) => {
                              return {
                                label: ct.name,
                                value: ct,
                              };
                            }
                          )}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-4">
                          What are your desired location(s)?
                        </Form.Label>
                        <GeoSelectCity
                          placeholder="Enter up to 4 Cities and States"
                          isMulti={true}
                          name="desiredCities"
                          value={this.state.desiredCities}
                          handleChange={this.handleSelectChange}
                          options={
                            this.state.desiredCities.length >= 4 ? [] : null
                          }
                        />
                        {this.state.desiredCities.length >= 4 && (
                          <p
                            style={{
                              marginTop: 40,
                              color: "#A5BBC2",
                            }}
                          >
                            <i>
                              You can only choose up to 4 desired locations. If
                              you desire to be anywhere in the United States,
                              drag the search distance slider all the way to the
                              right
                            </i>
                          </p>
                        )}
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Desired Work Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentType"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.employmentType)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Select Desired Work Type
                          </option>
                          {this.state.employmentTypes.map((employmentType) => (
                            <option value={JSON.stringify(employmentType)}>
                              {employmentType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>Search Distance</Form.Label>
                        <p className="m-0 p-0 text-muted">
                          We'll network you directly with hiring companies
                          within the below miles from your selected location(s).
                        </p>
                        <SliderValueContainer>
                          <SliderValue>
                            {this.state.desiredSearchDistanceMin === 400
                              ? "Nationwide"
                              : `${this.state.desiredSearchDistanceMin} miles`}
                          </SliderValue>
                        </SliderValueContainer>
                        <Slider
                          min={0}
                          max={400}
                          value={this.state.desiredSearchDistanceMin}
                          defaultValue={0}
                          onChange={(d) =>
                            this.onSliderChange("desiredSearchDistance", d)
                          }
                          marks={{
                            0: "0mi",
                            400: "Nationwide",
                          }}
                          allowCross={false}
                          tipFormatter={(value) => `${value}`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Label className="mt-5">
                          Desired Office Type(s)
                        </Form.Label>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "#999",
                            fontSize: 15,
                          }}
                        >
                          Please select the office type(s) you desire. You can choose up to four options.
                        </p>
                        <Select
                          onChange={(v) =>
                            this.handleMultiSelect(v, "desiredOfficeTypes")
                          }
                          defaultValue={this.state.desiredOfficeTypes}
                          styles={{
                            container: (base, state) => ({
                              ...base,
                              width: "100%",
                              outline: "none",
                            }),
                            control: (base, state) => ({
                              ...base,
                              border: "none",
                              borderBottom: "1px solid silver",
                              outline: "none",
                              padding: 0,
                              margin: 0,
                              borderRadius: 0,
                              outline: "none",
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              padding: 0,
                            }),
                            multiValue: (base, state) => ({
                              ...base,
                              borderRadius: 30,
                              backgroundColor: "rgb(196, 207, 213)",
                              fontWeight: "bold",
                              marginRight: 10,
                              marginBottom: 10,
                            }),
                          }}
                          placeholder="Select Desired Office Type(s)"
                          isMulti={true}
                          options={officeTypes.map((ct, index) => {
                            return {
                              label: ct,
                              value: index,
                            };
                          })}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          Your Pitch
                        </Form.Label>
                        <Form.Control
                          className="p-3"
                          style={{
                            padding: 20,
                            backgroundColor: "#F5F9FB",
                          }}
                          onChange={this.handleChange}
                          value={this.state.userPitch}
                          as="textarea"
                          rows="15"
                          name="userPitch"
                          maxLength={500}
                          placeholder="Sell yourself to hiring partners in 150 words or less! Highlighting personal experiences, certifications, and achievements is a great place to start. What makes you stand out? (Required for your profile to be completed)"
                        />
                        <div>
                          <p>
                            Characters Remaining:{" "}
                            {500 - this.state.userPitch.length}
                          </p>
                        </div>
                      </Col>
                    </Form.Row>

                    {/* <Form.Row>
                      <Form.Label className="mt-4">
                        We see that you're actively seeking in your job search.
                        In addition to receiving matches from Cannabis Insider Jobs Talent Community Recruiters,
                        do you want to see job listings from our hiring partners
                        in your job search?
                      </Form.Label>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        value="yes"
                        onChange={this.handleChange}
                        checked={this.state.thirdPartyRecruiters === "yes"}
                        name="thirdPartyRecruiters"
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        value="no"
                        onChange={this.handleChange}
                        checked={this.state.thirdPartyRecruiters === "no"}
                        name="thirdPartyRecruiters"
                      />
                    </Form.Row> */}
                    <Form.Row className="mt-3">
                      <Button
                        variant="outline-primary"
                        onClick={this.decrementStep}
                      >
                        Back
                      </Button>
                      <Button className="ml-auto" onClick={this.incrementStep}>
                        Next
                      </Button>
                    </Form.Row>
                  </Form>
                </ModalCol>
              </ModalContainerTwo>
            ),
            4: (
              <ModalContainerTwo>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: 175,
                    borderRadius: 8,
                    left: 30,
                    top: 30,
                  }}
                  src={require("images/logo/cji-logo.png")}
                />
                <ModalCol className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                  <img
                    style={{
                      width: 450,
                      bottom: 0,
                    }}
                    src={require("images/modals/gogig-career-writing-sample.png")}
                  />
                </ModalCol>
                <ModalCol className="bg-white p-5 w-100">
                  <Row>
                    <Col md={12}>
                      <h1>Communication Style Report</h1>
                      <p>
                        Cannabis Insider Jobs Talent Community uses AI and Natural
                        Language Processing to better understand your
                        communication style through the analysis of a writing
                        sample. Hiring professionals using Cannabis Insider Jobs Talent Community will communicate with you upon outreach
                        more effectively based on your Communication Style Report metrics generated here.
                      </p>
                      {/* <button class="loginBtn loginBtn--facebook">
                        Use My Facebook
                      </button> */}
                      <Form className="mt-3">
                        <Form.Row>
                          <Form.Label>
                            Please find a professional writing sample that
                            you've typed up that is at least 200 words or more
                            and paste it into the field below (you can update
                            this section later if you want):
                          </Form.Label>
                          <Form.Control
                            name="writingSampleType"
                            as="select"
                            onChange={this.handleChange}
                            value={this.state.writingSampleType}
                          >
                            <option value="" disabled>
                              Select a writing sample
                            </option>
                            {this.state.writingSampleTypes.map(
                              (writingSampleType) => (
                                <option value={writingSampleType.id}>
                                  {writingSampleType.name}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Row>
                        <Form.Row className="mt-4">
                          <Form.Control
                            className="p-3"
                            style={{
                              backgroundColor: "#F5F9FB",
                            }}
                            name="writingSample"
                            onChange={this.handleChange}
                            as="textarea"
                            rows="15"
                            placeholder="The most common examples of professional writing samples that we suggest you use are: Cover Letter, Professional Email, Personal Bio, LinkedIn Bio, Memo, Professional Report, Commercial Writing Sample, or any other writing piece that you've typed in a professional setting."
                          />
                        </Form.Row>
                        <div>
                          <p
                            style={{
                              color: "#999",
                            }}
                          >
                            Characters Remaining:{" "}
                            {1400 - this.state.writingSample.length < 0
                              ? "Sufficient"
                              : 1400 - this.state.writingSample.length}
                          </p>
                        </div>
                        <Form.Row className="mt-2">
                          <Button
                            variant="outline-primary"
                            onClick={this.decrementStep}
                          >
                            Back
                          </Button>
                          <Button
                            className="ml-auto"
                            onClick={this.handleSubmit}
                          >
                            Finish
                          </Button>
                        </Form.Row>
                      </Form>
                    </Col>
                  </Row>
                </ModalCol>
              </ModalContainerTwo>
            ),
          }[this.state.onboarding.stepper.currentStep]
        }
      </Modal>
    );
  }
}

export default CandidateOnboarding;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

const ModalRow = styled.div``;

const FacebookButton = styled.button``;

const SliderValueContainer = styled.div`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;

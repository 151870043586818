import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller, scrollToTop } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Box, OutlinedInput } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import { signUpRequest } from '../../constants/endpoints';

const useStyles = makeStyles({
  select: {
    width: '90%',
    padding: '8.5px 14px',
    fontFamily: 'Montserrat',
  },
  outlined: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  popoverRoot: {
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
  },
  formRoot: {
    width: '100%',
    backgroundColor: '#fff',
  },
  formInput: {
    borderRadius: 0,
    fontFamily: 'Montserrat',
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  errorOutline: {
    color: 'red',
  },
  errorOutlinedInput: {
    borderColor: 'red',
    '&:hover': {
      borderColor: '#b30000',
    },
  }
});

function TemplateInfo(props) {
  const {
    mobileLogo,
    contentHeroImg,
    contentHeroLogo,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink,
    recentlyAdded,
    contentBody,
    privacyForm,
  } = props;
  const [firstName, changeFirstName] = useState('');
  const [firstNameValidation, changeFirstNameValidation] = useState(false);
  const [lastName, changeLastName] = useState('');
  const [lastNameValidation, changeLastNameValidation] = useState(false);
  const [emailAddress, changeEmailAddress] = useState('');
  const [emailValidation, changeEmailValidation] = useState(false);
  const [phoneNumber, changePhoneNumber] = useState('');
  const [phoneNumberValidation, changePhoneNumberValidation] = useState(false);
  const [selectedOption, changeSelectedOption] = useState('Please select an option');
  const [selectedOptionValidation, changeSelectedOptionValidation] = useState(false);
  const [openOtherOption, changeOpenOtherOption] = useState(false);
  const [otherOptionField, changeOtherOptionField] = useState('');
  const [otherOptionFieldValidation, changeOtherOptionFieldValidation] = useState(false);
  const [confirmationForm, changeConfirmationForm] = useState(false);
  const [optionTextStyle, changeOptionTextStyle] = useState(false);
  const [mobileRes, changeMobileRes] = useState(false);
  const styles = useStyles();

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fields = [
    {
      name: 'First Name',
      value: firstName,
      rules: [
        {
          validate: (value) => !(value.length > 0),
          error: () => changeFirstNameValidation(true),
        },
      ],
    },
    {
      name: 'Last Name',
      value: lastName,
      rules: [
        {
          validate: (value) => !(value.length > 0),
          error: () => changeLastNameValidation(true),
        },
      ],
    },
    {
      name: 'Email',
      value: emailAddress,
      rules: [
        {
          validate: (value) => !(/\w+[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,3}/.test(value)),
          error: () => changeEmailValidation(true),
        },
      ],
    },
    {
      name: 'Phone Number',
      value: phoneNumber,
      rules: [
        {
          validate: (value) => !(value.length > 0),
          error: () => changePhoneNumberValidation(true),
        },
      ],
    },
    {
      name: 'Selected Option',
      value: selectedOption,
      rules: [
        {
          validate: (value) => !(value !== 'Please select an option'),
          error: () => changeSelectedOptionValidation(true),
        },
      ],
    },
    {
      name: 'Other Option',
      value: otherOptionField,
      rules: [
        {
          validate: (value) => selectedOption === 'Other (Please Share)' && !(value.length > 0),
          error: () => changeOtherOptionFieldValidation(true),
        }
      ]
    }
  ]

  const handleFormFieldsClear = () => {
    changeFirstName('');
    changeLastName('');
    changeEmailAddress('');
    changePhoneNumber('');
    changeSelectedOption('Please select an option');
    changeOtherOptionField('');
  }

  // const handleEmailValidation = () => {
  //   const regex = /\w+[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,3}/;
  //   if (!regex.test(emailAddress)) {
  //     changeEmailValidation(true);
  //     return null;
  //   }
  //   const request = {
  //     method: 'POST',
  //     data: {
  //       firstName,
  //       lastName,
  //       emailAddress,
  //       phoneNumber,
  //       selectedOption: openOtherOption ? otherOptionField : selectedOption,
  //     },
  //   };
  //   signUpRequest(request);
  //   changeConfirmationForm(true);
  // };

  const handleSubmitValidation = () => {
    let validation = true;
    fields.forEach((field) => {
      console.log(`field: ${field.name}`);
      console.log(`value: ${field.value}`);
      field.rules.forEach((rule) => {
        const valid = rule.validate(field.value);
        console.log(`rule: ${valid}`)
        if (valid) {
          rule.error();
          validation = false;
        }
      })
    });

    console.log(validation);

    if (validation) {
      const request = {
        method: 'POST',
        data: {
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          selectedOption: openOtherOption ? otherOptionField : selectedOption,
        },
      };
      signUpRequest(request);
      changeConfirmationForm(true);
    }
  }

  const handleFormClose = () => {
    changeConfirmationForm(false);
    changeOpenOtherOption(false);
    handleFormFieldsClear();
  };

  const handleOptionChange = (value) => {
    if (value !== "Please select an option") changeOptionTextStyle(true);
    changeSelectedOption(value);
  }

  useEffect(() => {
    // console.log(window.innerWidth)
    if (window.innerWidth > 900) {
      changeMobileRes(true);
    } else {
      changeMobileRes(false);
    }
  }, [])

  const signUpForm = (
    <div
      className={classes.formSignUp}
    >
      <Typography
        variant="h2"
        style={{
          color: '#278134',
          fontSize: '2.25rem',
          fontFamily: 'Montserrat',
          fontWeight: '700',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        Learn More
      </Typography>
      <Typography
        style={{
          margin: '10px 0',
          fontSize: '1rem',
          fontFamily: 'Montserrat',
          fontWeight: '400',
          textAlign: 'center',
          paddingBottom: '30px',
        }}
      >
        Learn more about Cannabis Insider
        <br />
        Jobs Talent Community.
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="First Name"
            variant="outlined"
            onChange={(e) => {
              if (firstNameValidation) changeFirstNameValidation(false);
              changeFirstName(e.target.value);
            }}
            value={firstName}
            label={firstNameValidation ? 'Please fill in this field' : null}
            error={firstNameValidation}
            classes={{
              root: styles.formRoot,
            }}
            InputProps={{
              classes: {
                root: styles.formInput,
                notchedOutline: mobileRes ? styles.notchedOutline : null,
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="Last Name"
            variant="outlined"
            onChange={(e) => {
              if (lastNameValidation) changeLastNameValidation(false);
              changeLastName(e.target.value);
            }}
            value={lastName}
            label={lastNameValidation ? 'Please fill in this field' :null}
            error={lastNameValidation}
            classes={{
              root: styles.formRoot,
            }}
            InputProps={{
              classes: {
                root: styles.formInput,
                notchedOutline: mobileRes ? styles.notchedOutline : null,
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Email Address"
            variant="outlined"
            onChange={(e) => {
              if (emailValidation) changeEmailValidation(false);
              changeEmailAddress(e.target.value);
            }}
            value={emailAddress}
            label={emailValidation ? 'Please use a valid email' : null}
            error={emailValidation}
            classes={{
              root: styles.formRoot,
            }}
            InputProps={{
              classes: {
                root: styles.formInput,
                notchedOutline: mobileRes ? styles.notchedOutline : null,
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Phone Number"
            variant="outlined"
            onChange={(e) => {
              if (phoneNumberValidation) changePhoneNumberValidation(false);
              changePhoneNumber(e.target.value);
            }}
            value={phoneNumber}
            label={phoneNumberValidation ? 'Please fill in this field' : null}
            error={phoneNumberValidation}
            classes={{
              root: styles.formRoot,
            }}
            InputProps={{
              classes: {
                root: styles.formInput,
                notchedOutline: mobileRes ? styles.notchedOutline : null,
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Select
            size="small"
            variant="outlined"
            onChange={(e) => {
              if (selectedOptionValidation) changeSelectedOptionValidation(false);
              handleOptionChange(e.target.value)
            }}
            value={selectedOptionValidation ? 'Please select a valid option' : selectedOption}
            renderValue={(selected) => selected}
            input={
              <OutlinedInput
                classes={{
                  notchedOutline: selectedOptionValidation ? styles.errorOutlinedInput : null,
                }}
              />
            }
            classes={{
              select: styles.select,
              outlined: selectedOptionValidation ? styles.errorOutline : !optionTextStyle ? styles.outlined : null,
            }}
            style={{
              display: 'block',
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: 0,
            }}
          >
            <MenuItem disabled value="">
              Please select an option
            </MenuItem>
            <MenuItem
              value="I am looking to hire Cannabis talent"
              onClick={() => changeOpenOtherOption(false)}
            >
              I am looking to hire Cannabis talent
            </MenuItem>
            <MenuItem
              value="I am looking for a job in the Cannabis Industry"
              onClick={() => changeOpenOtherOption(false)}
            >
              I am looking for a job in the Cannabis Industry
            </MenuItem>
            <MenuItem
              value="Other (Please Share)"
              onClick={() => changeOpenOtherOption(true)}
            >
              Other (Please Share)
            </MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Other Option"
            variant="outlined"
            onChange={(e) => {
              if (otherOptionFieldValidation) changeOtherOptionFieldValidation(false);
              changeOtherOptionField(e.target.value);
            }}
            value={otherOptionField}
            label={otherOptionFieldValidation ? 'Please fill in this field' : null}
            error={otherOptionFieldValidation}
            classes={{
              root: styles.formRoot,
            }}
            InputProps={{
              classes: {
                root: styles.formInput,
                notchedOutline: mobileRes ? styles.notchedOutline : null,
              }
            }}
            style={{
              display: openOtherOption ? 'inline-flex' : 'none',
            }}
          />
        </Grid>
        <Grid style={{padding: mobileRes === true ? '0px 12px': '0px 0px',}}>
          <Typography
            variant="caption"
            style={{fontFamily: 'Montserrat',}}
          >
            * By signing up, you have agreed to receive communication from Advance Local and Cannabis Insider
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formSignUpButton}
        >
          <Button
            className={classes.buttonSquareFilledGreen}
            onClick={() => handleSubmitValidation()}
            style={{
              margin: '20px 0',
              minWidth: '200px',
              height: '44px',
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const bodyText = (
    <Grid
      className="bodyContent"
      container
      style={{
        margin: '2.5rem 0 ',
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          className={classes.bodyTitleContainer}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways Cannabis Insider Jobs Talent Community Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Have real conversations with hiring companies that express interest in you —no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-green-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            className={classes.heroImg}
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '50%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={5}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
              <img
                src={contentHeroLogo}
                alt=""
                width={"60%"}
                style={{marginBottom: '15px'}}
              />
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
          </Grid>
     
          <Grid
            item
            xs={4}
            lg={3}
            className={classes.formSignUpDesktop}
            style={{
              alignItems: 'flex-end',
              zIndex: '10',
            }}
          >
            { signUpForm }
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.formSignUpMobile}
        style={{
          alignItems: 'flex-end',
          zIndex: '10',
        }}
      >
        { signUpForm }
      </Grid>
      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            style={{
              fontSize: '1.438rem',
              fontFamily: 'Montserrat',
              fontWeight: '700',
            }}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSquareOutlinedGreen}
            href='/getstarted'
            rel="noreferrer"
            style={{
              margin: '20px 0',
              width: '200px',
              height: '44px',
            }}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      { landingContent }
      <Popover
        open={confirmationForm}
        onClose={handleFormClose}
        classes={{
          paper: styles.popoverRoot,
        }}
        style={{
          overflowY: 'scroll',
          height: '100%',
          width: '100%',
          background: 'rgba(0,0,0,0.2)',
          boxShadow: 'none',
        }}
        disableScrollLock
      >
        <div
          className={classes.popupContainer}
        >
          <Typography
            variant="h2"
            className={classes.popupTitle}
          >
            Thank you for your interest in Cannabis Insider Jobs Talent Community.
          </Typography>
          <Typography
            style={{
              padding: '20px 0',
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              textAlign: 'center',
            }}
          >
            We will reach out when more information is available.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              className={classes.buttonSquareFilledGreen}
              onClick={() => handleFormClose()}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
              }}
            >
              Exit
            </Button>
          </div>
        </div>
      </Popover>
    </Grid>
  );
}

TemplateInfo.propTypes = {};

export default TemplateInfo;

import { useEffect, useState } from "react";

const useMediaQuery = (minWidth) => {
    const [isMobile, setMobile] = useState(window.innerWidth <= 768);

    const handleWindowSizeChange = () => {
      setMobile(window.innerWidth <= 768);
    }
    
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }, []);
    
    return isMobile
};

export default useMediaQuery;
import React, { useState } from "react";
import {Form, InputGroup, Button, Row, Col} from "react-bootstrap";
import classes from "./index.module.css";
import API from "../API"
  
export default function CouponInput({callback}) {
  const [couponCode, setCouponCode] = useState('');
  const [verifiedCouponObj, setVerifiedCouponObj] = useState(null);
  const [error, setError] = useState(null);

  const handleCouponVerify = () => {
    API.get(`/UserSubscriptions/verifyCoupon?code=${couponCode}`)
    .then(response => {
      let coupon = response.data
      if (coupon.valid === true) {
        setError(null)
        setVerifiedCouponObj(coupon)
        callback(coupon)
      } else {
        setError('Access code is expired')
        setVerifiedCouponObj(null)
        callback(null)
      }
    })
    .catch((error) => {
      setError('Access code is invalid')
      setVerifiedCouponObj(null)
      callback(null)
    })
  }

  return (
    <>
      {/* {error && <span className="text-danger">{error}</span>}
      {verifiedCouponObj && (
          <span className="text-primary">Code Applied: {verifiedCouponObj.name}</span>
      )} */}
        <Row>
          <InputGroup hasValidation className="mb-3 mt-3" style={{border: '1px solid #888ca8 !important;'}}>

            <Col xs={10}>
              <Form.Control
                size="lg" 
                type="text" 
                value={couponCode} 
                onChange={e => { setCouponCode(e.target.value) }} 
                style={{fontSize: 15, color: '#888ca8'}} 
                placeholder="Enter access code"
                isValid={!error && verifiedCouponObj}
              />
             <Form.Control.Feedback>
                {verifiedCouponObj ? verifiedCouponObj.name : ""}
              </Form.Control.Feedback>
            </Col>

            <Col xs={2}>

              <Button onClick={handleCouponVerify} variant="outline-secondary" className={classes.buttonFilledCoupon}>
                  APPLY CODE
              </Button>
            </Col>

          </InputGroup>

        </Row>
    </>
  )
}

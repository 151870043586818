import {
  PaymentElement,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import {Container, Spinner } from "react-bootstrap";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import classes from "./index.module.css";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState(null);
  const [subIntent, setSubIntent] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);


    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        // redirect url not used when redirect = 'if_required'
        return_url: ``,
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message + " (error: " + error.code + ")");
      } else {
        setMessage(
          "An unexpected error occurred. Please try again in a few minutes."
        );
      }
    }

    if (!error && paymentIntent) {
      setSubIntent(paymentIntent.status);
    }
    setIsLoading(false);
    if (paymentIntent && paymentIntent.status === "succeeded") {
      props.callback();
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        {message && <div style={{ color: "red" }}>{message}</div>}
        {subIntent === "succeeded" && (
          <div style={{ color: "#278134" }}>Payment processed successfully</div>
        )}

        <PaymentElement id="payment-element" className="pt-4" />
        <br />

        <button
          className={classes.buttonFilledPrimary}
          disabled={!stripe || isLoading ||!elements}
          id="submit"
        >
          {isLoading ? <Spinner animation="grow" variant="success" /> : (<span id="button-text">SUBMIT PAYMENT</span>)}
        </button>

      </form>
    </Container>
  );
}
import React, { Component } from "react";
import styled from "styled-components";
import { Avatar } from "./Avatars.stories.js";
import { Paragraph } from "./Typography.stories.js";
import CandidateSwitch from "../images/icons/switch/candidate.svg";
import RecruiterSwitch from "../images/icons/switch/recruiter.svg";
import { PrimaryLink } from "./Links.stories";
import { Link } from "react-router-dom";
import API from "API";
import { Tooltip, Overlay, OverlayTrigger, Button } from "react-bootstrap";
import { MobileOnlyView, BrowserView } from "react-device-detect";

// icons
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";

export default {
  title: "Profile Card",
};

const domain = "https://talent.cannabisinsiderjobs.com:4000";

class ProfileCard extends Component {
  state = {
    coverPhoto: "",
    profilePhoto: "",
    recruiterEnabled: false,
    anonymousUsername: "",
    profileComplete: false,
    avatar: localStorage.getItem('avatarSlug') || ""
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        coverPhoto: response.data.coverPhoto,
        profilePhoto: response.data.profilePhoto,
        avatar: response.data.avatar.slug,
        anonymousUsername: response.data.anonymousUsername,
        profileComplete: response.data.profileComplete,
      });
      localStorage.setItem('avatarSlug', response.data.avatar.slug)
    });
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/profileView/count`
    ).then((response) => {
      this.setState({
        profileViews: response.data.count,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/candidateMatches/count`
    ).then((response) => {
      this.setState({
        matches: response.data.count,
      });
    });
  }

  render() {
    return (
      <>
        <StyledProfileCardContainer>
          <StyledProfileCardCoverContainer>
            <MobileOnlyView>
              <StyledProfileCardImage
                style={{ height: null, width: "100%" }}
                src={
                  this.state.coverPhoto ||
                  `${domain}/api/Attachments/gogig-v3/download/cannabis-cover-0.jpg`
                }
              />
            </MobileOnlyView>
            <BrowserView>
              <StyledProfileCardImage
                style={{
                  height: 100,
                }}
                src={
                  this.state.coverPhoto ||
                  `${domain}/api/Attachments/gogig-v3/download/cannabis-cover-0.jpg`
                }
              />
            </BrowserView>
          </StyledProfileCardCoverContainer>
          <BrowserView>
            <StyledProfileCardNameContainer>
              <StyledProfileCardNameWrapper>
                <div>
                  <Avatar character={this.state.avatar} height={80} width={80} />
                </div>
                {!this.state.profileComplete ? (
                  <div>
                    <Paragraph size="14px" className="mt-3">
                      <strong>User {this.state.anonymousUsername}</strong>
                    </Paragraph>
                  </div>
                ) : (
                  <div
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Paragraph size="14px" className="mt-2 text-center m-0 p-0">
                      <strong>User {this.state.anonymousUsername}</strong>
                    </Paragraph>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 12,
                      }}
                    >
                      <p className="text-center m-0 p-0 text-muted mb-3">
                        Your profile is 100% complete{" "}
                      </p>
                      <img
                        style={{ width: 16, marginLeft: 5, marginBottom: 14 }}
                        src={require("../images/icons/medal.svg")}
                      />
                    </div>
                  </div>
                )}
              </StyledProfileCardNameWrapper>
            </StyledProfileCardNameContainer>
          </BrowserView>
          <MobileOnlyView>
            <StyledProfileCardNameContainer>
              <StyledProfileCardNameWrapper>
                <div>
                  <Avatar character={this.state.avatar} height={80} width={80} />
                </div>
                <div>
                  <Paragraph size="14px" className="mt-3">
                    <strong>User {this.state.anonymousUsername}</strong>
                  </Paragraph>
                </div>
              </StyledProfileCardNameWrapper>
            </StyledProfileCardNameContainer>
          </MobileOnlyView>
          <StyledProfileCardAnalayticsContainer>
            <StyledProfileCardViewsContainer>
              <Paragraph size="14px" removeMargins>
                <strong>{this.state.profileViews}</strong>
              </Paragraph>
              <Paragraph size="14px" muted removeMargins>
                Views
              </Paragraph>
            </StyledProfileCardViewsContainer>
            <StyledProfileCardMatchesContainer>
              <Paragraph size="14px" removeMargins>
                <strong>{this.state.matches}</strong>
              </Paragraph>
              <Paragraph size="14px" muted removeMargins>
                Matches
              </Paragraph>
            </StyledProfileCardMatchesContainer>
          </StyledProfileCardAnalayticsContainer>
          <StyledProfileCardSwitchContainer>
            <Link to="/recruiter/dashboard" style={{ cursor: "pointer" }}>
              <img style={{ cursor: "pointer" }} src={CandidateSwitch} />
            </Link>
          </StyledProfileCardSwitchContainer>
          <MobileOnlyView>
            <StyledProfileCardLinkContainer style={{ cursor: "pointer" }}>
              <Link to="/logout" style={{ cursor: "pointer" }}>
                <PrimaryLink size="14px" style={{ cursor: "pointer" }}>
                  Logout
                </PrimaryLink>
              </Link>
            </StyledProfileCardLinkContainer>
          </MobileOnlyView>
          <BrowserView>
            <StyledProfileCardLinkContainer>
              <Link to="/profile">
                <PrimaryLink size="14px">View My Profile</PrimaryLink>
              </Link>
            </StyledProfileCardLinkContainer>
          </BrowserView>

          <ButtonsContainer>
            <a className="w-100" target="_blank" href="https://www.cannabisinsiderjobs.com">
              <Button size="sm" block>
                Cannabis Insider Jobs
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100" target="_blank" href="https://www.cannabisworkforce.org">
              <Button size="sm" block>
                Get Certified
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100"
              target="_blank" href="https://njcannabisinsider.nj.com/about">
              <Button size="sm" block>
                Cannabis Industry Insight NJ
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100"
              target="_blank" href="https://www.newyorkupstate.com/cannabis-insider">
              <Button size="sm" block>
                Cannabis Industry Insight NY
              </Button>
            </a>
          </ButtonsContainer>
          
          <ButtonsContainer>
            <Link className="w-100"
              to="/jobs">
              <Button size="sm" block>
                Hiring Partners
              </Button>
            </Link>
          </ButtonsContainer>

          <ButtonsContainer>
            <a
              className="w-100"
              target="_blank"
              href="https://www.ourcommunityharvest.com/online-courses"
            >
              <Button size="sm" block>
                NJ Required Cannabis Handlers Online Course
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a
              className="w-100"
              target="_blank"
              href="https://www.ourcommunityharvest.com/individuals"
            >
              <Button size="sm" block>
                Cannabis Education for Curious Individuals
              </Button>
            </a>
          </ButtonsContainer>

          {/* <SocialContainer>
            <a href="https://twitter.com/GigaVoltHub" target="_blank">
              <AiOutlineTwitter
                style={{
                  color: "#47A836",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/gigavolthub" target="_blank">
              <AiFillLinkedin
                style={{
                  color: "#47A836",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
            </a>
          </SocialContainer> */}
        </StyledProfileCardContainer>
      </>
    );
  }
}

class RecruiterProfileCard extends Component {
  state = {
    coverPhoto: "",
    anonymousUsername: "",
    avatar: localStorage.getItem('avatarSlug') || ""
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        coverPhoto: response.data.coverPhoto,
        avatar: response.data.avatar.slug,
        anonymousUsername: response.data.anonymousUsername,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/profileView/count`
    ).then((response) => {
      this.setState({
        profileViews: response.data.count,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterMatches/count`
    ).then((response) => {
      this.setState({
        matches: response.data.count,
      });
    });
  }
  render() {
    return (
      <StyledProfileCardContainer>
        <StyledProfileCardCoverContainer>
          <StyledProfileCardImage
            src={
              this.state.coverPhoto ||
              `${domain}/api/Attachments/gogig-v3/download/cannabis-cover-0.jpg`
            }
          />
        </StyledProfileCardCoverContainer>
        <StyledProfileCardNameContainer>
          <StyledProfileCardNameWrapper>
            <div>
              <Avatar character={this.state.avatar} height={80} width={80} />
            </div>
            <div>
              <Paragraph size="14px" className="mt-3">
                <strong>User {this.state.anonymousUsername}</strong>
              </Paragraph>
            </div>
          </StyledProfileCardNameWrapper>
        </StyledProfileCardNameContainer>
        <StyledProfileCardAnalayticsContainer>
          <StyledProfileCardViewsContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.profileViews}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Messsages
            </Paragraph>
          </StyledProfileCardViewsContainer>
          <StyledProfileCardMatchesContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.matches}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Matches
            </Paragraph>
          </StyledProfileCardMatchesContainer>
        </StyledProfileCardAnalayticsContainer>
        <StyledProfileCardSwitchContainer>
          <Link to="/getstarted">
            <img src={RecruiterSwitch} />
          </Link>
        </StyledProfileCardSwitchContainer>
        <BrowserView>
          <StyledProfileCardLinkContainer>
            <Link to="/profile">
              <PrimaryLink size="14px">View My Profile</PrimaryLink>
            </Link>
          </StyledProfileCardLinkContainer>
        </BrowserView>

        <ButtonsContainer>
            <a className="w-100" target="_blank" href="https://www.cannabisinsiderjobs.com">
              <Button size="sm" block>
                Cannabis Insider Jobs
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100" target="_blank" href="https://www.cannabisworkforce.org">
              <Button size="sm" block>
                Get Certified
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100"
              target="_blank" href="https://njcannabisinsider.nj.com/about">
              <Button size="sm" block>
                Cannabis Industry Insight NJ
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a className="w-100"
              target="_blank" href="https://www.newyorkupstate.com/cannabis-insider">
              <Button size="sm" block>
                Cannabis Industry Insight NY
              </Button>
            </a>
          </ButtonsContainer>
          
          <ButtonsContainer>
            <Link className="w-100"
              to="/jobs">
              <Button size="sm" block>
                Hiring Partners
              </Button>
            </Link>
          </ButtonsContainer>

          <ButtonsContainer>
            <a
              className="w-100"
              target="_blank"
              href="https://www.ourcommunityharvest.com/online-courses"
            >
              <Button size="sm" block>
                NJ Required Cannabis Handlers Online Course
              </Button>
            </a>
          </ButtonsContainer>

          <ButtonsContainer>
            <a
              className="w-100"
              target="_blank"
              href="https://www.ourcommunityharvest.com/individuals"
            >
              <Button size="sm" block>
                Cannabis Education for Curious Individuals
              </Button>
            </a>
          </ButtonsContainer>

        {/* <SocialContainer>
          <a href="https://twitter.com/GigaVoltHub" target="_blank">
            <AiOutlineTwitter
              style={{
                color: "#47A836",
                fontSize: 24,
                fontWeight: "bold",
                marginLeft: 10,
                marginRight: 10,
              }}
            />
          </a>
          <a href="https://www.linkedin.com/company/gigavolthub" target="_blank">
            <AiFillLinkedin
              style={{
                color: "#47A836",
                fontSize: 24,
                fontWeight: "bold",
                marginLeft: 10,
                marginRight: 10,
              }}
            />
          </a>
        </SocialContainer> */}
      </StyledProfileCardContainer>
    );
  }
}

export { ProfileCard, RecruiterProfileCard };

const StyledLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  `;

const StyledLinkButton = styled.div`
  color: #121A50;
  justify-content: center;
  text-align: center;
  display: flex;
  flex: 1;
  background-color: #fff;
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #efefef;
  transition: 0.3s;
  &:hover {
    background-color: #fff;
  }
  `;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;
  padding: 20px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 25px;
  border-top: 1px solid #efefef;
`;

const StyledProfileCardContainer = styled.div`
  background: white;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const StyledProfileCardCoverContainer = styled.div`
  background: #f5f5f5;
  width: 100%;
  overflow: hidden;
`;

const StyledProfileCardNameContainer = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StyledProfileCardAnalayticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #efefef;
  align-items: center;
`;
const StyledProfileCardSwitchContainer = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  padding: 20px;
  img {
    max-width: 100%;
  }
`;
const StyledProfileCardLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledProfileCardViewsContainer = styled.div`
  border-right: 0.5px solid #efefef;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
const StyledProfileCardMatchesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-left: 0.5px solid #efefef;
  padding: 20px;
`;

const StyledProfileCardNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 35px;
  height: 85px;
`;

const StyledProfileCardImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

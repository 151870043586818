import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Modal,
  Badge,
  Card,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CircleIcon from '@material-ui/icons/Brightness1';
import API from "../API";
import styled from "styled-components";
import classes from "./index.module.css";
import CouponInput from "./CouponInput";

export default function StripeProductsScreen({callback}) {
  const [prices, setPrices] = useState();
  const [coupon, setCoupon] = useState(null);
  const [codeIsHidden, setIsCodeHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    API.get(`/UserSubscriptions/listPrices`)
      .then((response) => {
        setPrices(response.data.prices);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const calcSavings = (pMonthly, pYearly) => {

    const discountAmount = (pMonthly * 12) - pYearly
    
    const discountPercent = (discountAmount / (pMonthly * 12)) * 100

    return discountPercent
  }

  const PriceCard = ({ pricePlan, index }) => {
    let yearIndex = 0,
      monthIndex = 1;
    if (pricePlan.recurring.interval === "year") {
      if (index === 0) monthIndex = 1;
      if (index === 1) monthIndex = 0;
      yearIndex = index;
    }
    return (
      <Card
        style={{
          padding: "15px 0px",
          borderRadius: 0,
          top: "-30%"
        }}
        onClick={() => callback(pricePlan, coupon)} 
      >
        <Card.Body style={{ textAlign: "center" }}>
          <Card.ImgOverlay>
          {pricePlan.recurring.interval === "year" && (
            <Badge
              pill
              style={{
                backgroundColor: "#DC3545",
                color: "#FFFFFF",
                fontSize: "16px",
                zIndex: 1,
                position: "relative",
                top: -30,
              }}
            >
              SAVE {calcSavings(prices[monthIndex].unit_amount / 100, prices[yearIndex].unit_amount / 100).toFixed()}%
            </Badge>
          )}
          </Card.ImgOverlay>

          <Card.Title style={{ fontSize: 22 }}>
            {pricePlan.recurring.interval === "year" ? "Annual" : "Monthly"}
          </Card.Title>
          
          <Card.Text style={{ fontSize: 16, padding: "15px 0px" }}>
            ${pricePlan.unit_amount / 100}/{pricePlan.recurring.interval}
          </Card.Text>
          <Button variant="outline-secondary" className={classes.buttonFilledCoupon}>

            Subscribe
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const BulletedListItem = ({text}) => (
    <ListGroup.Item className="bg-transparent border-0" as="li">
      <CircleIcon style={{fontSize: 18, paddingRight: 10, paddingBottom: 2, marginLeft: '-1.15rem', color: '#47A836'}}/>
      {text}
    </ListGroup.Item>
  )

  return (
    <>
    {isLoading ? (
       <Container>
          <Row style={{height: "40vh", alignItems: 'center', justifyContent: 'center'}}>
              <Spinner animation="grow" variant="success"  />
              {/* <span className={classes.ellipsisAnimated}>Processing</span> */}
          </Row>
       </Container>
    ) : (
    <Modal show={true} dialogClassName="modal-stripe">
      <Modal.Body className="p-0">
        <Container fluid>
          {prices && ( 
            <>
            <Row>
              <Col className="p-0">
                <ProductCardTop>
                  Subscribe to CannabisInsiderJobs.com Talent Community,
                  <br /> 
                  a cannabis-industry focused professional growth network.
                  <br />
                  <span style={{fontSize: 18, fontWeight: 500}}>
                  Discover talent for your organization’s hiring needs today - no job posting required!
                  </span>
               </ProductCardTop>
              </Col>
            </Row>
            <Row style={{backgroundColor: "#edeff3"}}>
              <Col sm={1} xl={2}/>
              {prices.slice(0, 2).map((price, index) => (
                  <Col key={index} sm={5} xl={4}>
                    <PriceCard pricePlan={price} index={index} />
                  </Col>
              ))}
              
              <Col sm={1} xl={2}/>
            </Row>
            <Row style={{backgroundColor: "#edeff3", paddingBottom: "15px"}}>
              <Col sm={1} xl={2}/>
              <Col sm={10} xl={8} style={{top: -20}}>
                <ListGroup as="ol" style={{ listStyleType: 'decimal !important;' }}>
                  <BulletedListItem text={'Discover talent with cannabis-related experience, or those who desires to work in the cannabis industry, today'} />
                  <BulletedListItem text={'Utilize our desires-based matching algorithm to connect with talent that match your hiring needs'} />
                  <BulletedListItem text={'Have conversations with active AND passive talent looking for career opportunities near you'} />
                  <BulletedListItem text={'Preview communication styles of potential candidates before connecting'} />
                </ListGroup>
              </Col>
              <Col sm={1} xl={2}/>
            </Row>
            <Row style={{backgroundColor: "#edeff3", justifyContent: "flex-end", padding: 25, color: "#888ca8"}} onClick={() => (setIsCodeHidden(!codeIsHidden))}>
              Have an access code?
            </Row>
          </>
          )}

          { !codeIsHidden &&
           <CouponInput callback={setCoupon} />
          }

        </Container>
      </Modal.Body>
    </Modal>
    )}
    </>
  );
}

const ProductCardTop = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 26px;
  background-image: url("../images/cannabis-checkout-bg.jpg");
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.55);
  padding-top: 100px;
  padding-bottom: 130px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
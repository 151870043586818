import React, { Component } from "react";
import API from "API";
import {
  Modal,
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GeoSelectState, GeoSelectCity } from "GeoSelect";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import queryString from 'query-string'
import StripeCheckoutScreen from "../Stripe/StripeCheckoutScreen";
import StripeProductsScreen from "../Stripe/StripeProductsScreen";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const officeTypes = ["Remote Work", "Office Work", "Office-first hybrid", "Remote-first hybrid"]

class RecruiterOnboarding extends Component {
  state = {
    stripeCustomer: {},
    onboarding: {
      currentStep: 0,
    },
    employeeForecast: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    companyEmail: "",
    linkedIn: "",
    employmentType: {},
    employmentTypes: [],
    phoneNumber: "",
    recruiterEnabled: true,
    industries: [],
    jobPositions: [],
    jobPositionSpecialties: [],
    companyTypes: [],
    remoteOk: false,
    jobLevels: [],
    degrees: [],
    jobSearchStages: [],
    searchTitle: "",
    hiringCompany: "",
    jobPosition: {},
    jobPositionSpecialty: {},
    industry: {},
    degree: {},
    salaryMin: 0,
    salaryMax: 0,
    jobLevel: {},
    jobSearchStage: {},
    workExperienceMin: 0,
    workExperienceMax: 0,
    searchDistanceMin: 0,
    searchDistanceMax: 0,
    companyType: {},
    cities: [],
    newRecruiter: false,
    officeType: "",
    otherExplanation: "",
  };

  componentDidMount() {

    API.get(`/UserProfiles/${this.props.userToken.userId}`)
      .then((response) => {
        this.setState(response.data);
      })
      .then(() => {
        if (
          (this.state.firstTimeRecruiterMode === true &&
          this.state.recruiterEnabled === true)
        ) {
          this.setState({
            recruiterEnabled: false,
            onboarding: {
              currentStep: 5,
            },
          });
        }
      });
    API.get(
      `/Industries?filter={"order": "name ASC", "where": {"type":"C"} }`
    ).then((response) => {
      this.setState({
        industries: response.data,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      // Reorder with a filter so that all Cannabis Insider Jobs Talent Community job positions are at the top of the list
      this.setState({
        jobPositions: [
          ...response.data.filter((pos) => pos.type === "V"),
          ...response.data.filter((pos) => pos.type === "G"),
        ],
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data,
        });
      }
    );

    API.get(`/EmploymentTypes`).then((response) => {
      this.setState({
        employmentTypes: response.data,
      });
    });

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          companyTypes: response.data,
        });
      }
    );

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(`/Degrees?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        degrees: response.data,
      });
    });

    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });

    if (this.props.location.search.includes("true")) {
      this.setState({
        newRecruiter: true,
      });
    }
  }

  selectPricePlan = (pricePlan, coupon) => {
    if (coupon) {
      this.setState({selectedPrice: pricePlan, coupon: coupon.id})
    } else {
      this.setState({selectedPrice: pricePlan})
    }
    this.handleNext()
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleNext = () => {
    this.setState({
      onboarding: {
        currentStep: this.state.onboarding.currentStep + 1,
      },
    });
  };

  handleNextSearchQueryModal = () => {
    console.log(this.state)
    if (this.state.modal.currentStep === 0) {
      if (
        this.state.searchTitle === "" ||
        this.state.hiringCompany === "" ||
        this.state.officeType === [] ||
        this.state.jobPosition.id === "" ||
        this.state.jobPositionSpecialty.id === "" ||
        this.state.industry.id === "" ||
        this.state.degree.id === "" ||
        this.state.jobLevel.id === "" ||
        this.state.jobSearchStage.id  === "" ||
        this.state.companyType.id  === "" ||
        this.state.cities === "" ||
        this.state.states === "" ||
        // this.state.searchDistanceMax  === 0 ||
        this.state.searchDistanceMin  === 0 ||
        this.state.employmentType.id  === ""
        ) {
          this.setState({
            error: "Please fill in empty fields and try again"
          })
      } else {
        this.setState({
          modal: {
            currentStep: this.state.modal.currentStep + 1,
          },
        });
      }
    }
  }

  handleCreateNewRecruiter = async () => {
    API.post(`/RecruiterProfiles`, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      jobTitle: this.state.jobTitle,
      companyEmail: this.state.companyEmail,
      phoneNumber: this.state.phoneNumber,
      linkedIn: this.state.linkedIn,
      userProfileId: this.props.userToken.userId,
      pushId: this.state.pushId,
    }).then((response) => {
      this.setState({recruiterProfileId: response.data.id})
      API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        recruiterEnabled: true,
        recruiterProfileId: response.data.id,
        firstTimeRecruiterMode: true,
        newRecruiter: true,
      });
    }).then(() => {
      this.setState({recruiterEnabled: true, newRecruiter: true})
      this.handleNext()
    })
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;
    this.setState({
      [name]: [inputVals],
    });
  };

  handleCreateSearchQuery = ({ skipSkills }) => {
    if (skipSkills === true || this.state.skills.trim() === "") {
      API.post(`/RecruiterSearchQueries`, {
        searchTitle: this.state.searchTitle,
        hiringCompany: this.state.hiringCompany,
        officeType: this.state.officeType,
        jobPositionId: this.state.jobPosition.id,
        jobPositionSpecialtyId: this.state.jobPositionSpecialty.id,
        industryId: this.state.industry.id,
        degreeId: this.state.degree.id,
        salaryMin: this.state.salaryMin,
        salaryMax: this.state.salaryMax,
        jobLevelId: this.state.jobLevel.id,
        jobSearchStageId: this.state.jobSearchStage.id,
        workExperienceMin: this.state.workExperienceMin,
        workExperienceMax: this.state.workExperienceMax,
        companyTypeId: this.state.companyType.id,
        userProfileId: this.props.userToken.userId,
        cities: this.state.cities,
        states: this.state.states,
        searchDistanceMax: this.state.searchDistanceMax,
        searchDistanceMin: this.state.searchDistanceMin,
        employmentTypeId: this.state.employmentType.id,
      })
    } else {
      API.post(`/RecruiterSearchQueries`, {
        searchTitle: this.state.searchTitle,
        hiringCompany: this.state.hiringCompany,
        officeType: this.state.officeType,
        jobPositionId: this.state.jobPosition.id,
        jobPositionSpecialtyId: this.state.jobPositionSpecialty.id,
        industryId: this.state.industry.id,
        degreeId: this.state.degree.id,
        salaryMin: this.state.salaryMin,
        salaryMax: this.state.salaryMax,
        jobLevelId: this.state.jobLevel.id,
        skills: this.state.skills
          .trim()
          .split(",")
          .map((skill) => skill.trim()),
        jobSearchStageId: this.state.jobSearchStage.id,
        workExperienceMin: this.state.workExperienceMin,
        workExperienceMax: this.state.workExperienceMax,
        companyTypeId: this.state.companyType.id,
        userProfileId: this.props.userToken.userId,
        cities: this.state.cities,
        states: this.state.states,
        searchDistanceMax: this.state.searchDistanceMax,
        searchDistanceMin: this.state.searchDistanceMin,
        employmentTypeId: this.state.employmentType.id,
      })
    }

    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        recruiterEnabled: true,
        firstTimeRecruiterMode: false,
        newRecruiter: false,
    }).then(() =>{
      this.setState({
        recruiterEnabled: true,
        firstTimeRecruiterMode: false,
        newRecruiter: false,
      })
    })
  };

  onSliderChange = (name, value) => {
    if (name === "salary") {
      this.setState({
        salaryMin: value[0],
        salaryMax: value[1],
      });
    }

    if (name === "experience") {
      this.setState({
        workExperienceMin: value[0],
        workExperienceMax: value[1],
      });
    }

    if (name == "searchDistance") {
      this.setState({
        searchDistanceMin: value,
      });
    }
  };

  render() {
    return (
      <Modal
        show={!this.state.recruiterEnabled || this.state.newRecruiter === true}
        size="lg"
        dialogClassName="modal-65w"
        centered
      >
        <Modal.Body>
          {
            {
              0: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/cji-logo.png")}
                  />
                  <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <img
                      style={{
                        height: "60%",
                        top: 85,
                        position: "relative",
                      }}
                      src={require("images/modals/blk-man-think.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    {this.state.newRecruiter ? (
                      <div>
                        <h1>You are trying to access Hiring Mode</h1>
                        <p>
                          In the Hiring Mode of the platform, you will be able
                          to communicate with the Cannabis Insider Jobs Talent
                          Community team about your hiring needs, which will
                          give you access to their entire network.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h1>You are trying to access Hiring Mode</h1>
                        <p>
                          In the Hiring Mode of the platform, you will be able
                          to communicate with the Cannabis Insider Jobs Talent
                          Community team about your hiring needs, which will
                          give you access to their entire network.
                        </p>
                      </div>
                    )}
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            Please select which best describes you from the
                            selections below:
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="employeeForecast"
                            onChange={this.handleChange}
                            value={this.state.employeeForecast}
                          >
                            <option value="" disabled>
                              How many employees are you forecasted to hire in
                              the next 12 months?
                            </option>

                            <option value="<20">1-20</option>
                            <option value="20-50">20-50</option>
                            <option value="50-100+">50-100+</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>

                      {/* 
                      {this.state.employeeForecast === "other" && (
                        <Form.Row>
                          <Col>
                            <Form.Control
                              className="mt-2"
                              style={{
                                backgroundColor: "#F5F9FB",
                              }}
                              name="otherExplanation"
                              onChange={this.handleChange}
                              as="textarea"
                              rows="5"
                              value={this.state.otherExplanation}
                              placeholder="Please explain..."
                            />
                          </Col>
                        </Form.Row>
                      )} */}

                      <Form.Row className="mt-4">
                        {!this.state.newRecruiter && (
                          <Link to="/getstarted">
                            <Button variant="outline-primary" className=" mr-2">
                              Cancel
                            </Button>
                          </Link>
                        )}
                        <Button className="ml-auto" onClick={this.handleNext}>
                          Next
                        </Button>
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
              1: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/cji-logo.png")}
                  />
                  <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                    <img
                      style={{
                        width: 300,
                        position: "relative",
                        top: 200,
                      }}
                      src={require("images/modals/blk-man-think.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    {this.state.employeeForecast === "<20" ? (
                      <div>
                        <h1>
                          Submit your Cannabis Insider Jobs Talent Community
                          hiring access request
                        </h1>
                        <p>Fill out the form below:</p>
                      </div>
                    ) : (
                      <div>
                        <h1>
                          Submit your Cannabis Insider Jobs Talent Community
                          hiring access request
                        </h1>
                        <p>Fill out the form below:</p>
                      </div>
                    )}
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type first name here"
                            name="firstName"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type last name here"
                            name="lastName"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your company name here"
                            name="companyName"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Your job title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your job title here"
                            name="jobTitle"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>Company e-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type your company e-mail here"
                            name="companyEmail"
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type your phone number here"
                            name="phoneNumber"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>LinkedIn Profile Link</Form.Label>
                          <Form.Control
                            type="textarea"
                            placeholder="Enter your LinkedIn Profile"
                            name="linkedIn"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        {!this.state.newRecruiter && (
                          <Link to="/getstarted">
                            <Button variant="outline-primary" className="mr-2">
                              Cancel
                            </Button>
                          </Link>
                        )}
                        {this.state.recruiterEnabled ? (
                          <Button className="ml-auto" onClick={this.handleNext}>
                            Next
                          </Button>
                        ) : (
                          <Button
                            className="ml-auto"
                            onClick={this.handleNext}
                          >
                            Next
                          </Button>
                        )}
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
              2: (
                <StripeProductsScreen callback={this.selectPricePlan} />
              ),
              3: (
                <StripeCheckoutScreen grantHiring={this.handleCreateNewRecruiter} selectedPrice={this.state.selectedPrice} coupon={this.state.coupon ? this.state.coupon : null}  userToken={this.props.userToken} email={this.state.companyEmail} firstName={this.state.firstName} lastName={this.state.lastName} />
              ),
              4: 
              <ModalContainer>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: 175,
                    borderRadius: 8,
                    left: 30,
                    top: 30,
                  }}
                  src={require("images/logo/cji-logo.png")}
                />
                <ModalColumn className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                  <img
                    style={{
                      width: 400,
                      bottom: 0,
                    }}
                    src={require("images/modals/blk-man-think.png")}
                  />
                </ModalColumn>
                <ModalColumn className="p-5 w-100 bg-white">
                  {this.state.employeeForecast === "<20" ? (
                    <div>
                      <h1>
                        Congratulations!<br />Welcome to Cannabis Insider Jobs Talent Community.
                      </h1>
                      <p>
                        Your payment has been successfully processed and you've been granted hiring access. A confirmation email has been sent to your inbox with subscription details. Click below to create your first search to find talent now!
                      </p>
                   </div>
                  ) : (
                    <div>
                      <h1>
                        Congratulations!<br />Welcome to Cannabis Insider Jobs Talent Community.
                      </h1>
                      <p>
                       Your payment has been successfully processed and you've been granted hiring access. A confirmation email has been sent to your inbox with subscription details. Click below to create your first search to find talent now!
                      </p>
                    </div>
                  )}
                  <Form.Row className="mt-4">
                      <Button
                        className="mr-auto"
                        onClick={this.handleNext}
                      >
                        Create Search
                      </Button>

                  </Form.Row>
                </ModalColumn>
              </ModalContainer>,
              5: (
                <ModalContainer>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      width: 175,
                      borderRadius: 8,
                      left: 30,
                      top: 30,
                    }}
                    src={require("images/logo/cji-logo.png")}
                  />
                  <ModalColumn>
                    <img
                      style={{
                        width: 400,
                      }}
                      src={require("images/modals/gogig-career-desires.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    <h1>Build a New Cannabis Insider Jobs Talent Community Search</h1>
                    <p className="text-muted">
                      You can build as many searches as you want after you build
                      this one.
                    </p>
                    {
                      this.state.error && <p className="text-danger">{this.state.error}</p>
                    }
                    <Form.Row>
                      <Col>
                        <Form.Label>Search Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="searchTitle"
                          onChange={this.handleChange}
                          placeholder="Search Title"
                        />
                      </Col>
                      <Col>
                        <Form.Label>Hiring Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="hiringCompany"
                          onChange={this.handleChange}
                          placeholder="Company you're hiring on behalf of"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What type of position are you hiring for?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPosition"
                          value={JSON.stringify(this.state.jobPosition)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Search Position Type
                          </option>
                          {this.state.jobPositions.map((jobPosition) => (
                            <option value={JSON.stringify(jobPosition)}>
                              {jobPosition.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    {Object.keys(this.state.jobPosition).length !== 0 && (
                      <Form.Row className="mt-4">
                        <Col>
                          <Form.Label>
                            What specialty within {this.state.jobPosition.name}{" "}
                            are you hiring for?
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.handleChange}
                            name="jobPositionSpecialty"
                            value={JSON.stringify(
                              this.state.jobPositionSpecialty
                            )}
                          >
                            <option disabled value={JSON.stringify({})}>
                              Select A Position Specialty
                            </option>
                            {this.state.jobPositionSpecialties.map(
                              (jobPositionSpecialty) => {
                                if (
                                  jobPositionSpecialty.jobPositionId ===
                                  this.state.jobPosition.id
                                ) {
                                  return (
                                    <option
                                      value={JSON.stringify(
                                        jobPositionSpecialty
                                      )}
                                    >
                                      {jobPositionSpecialty.name}
                                    </option>
                                  );
                                }
                              }
                            )}
                          </Form.Control>
                        </Col>
                      </Form.Row>
                    )}
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Hiring Company Industry</Form.Label>
                        <Form.Control
                          as="select"
                          name="industry"
                          value={JSON.stringify(this.state.industry)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.industries.map((industry) => (
                            <option value={JSON.stringify(industry)}>
                              {industry.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Minimum Degree Level Required</Form.Label>
                        <Form.Control
                          as="select"
                          name="degree"
                          value={JSON.stringify(this.state.degree)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.degrees.map((degree) => (
                            <option value={JSON.stringify(degree)}>
                              {degree.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Minimum/Maximum Salary</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              ${this.state.salaryMin} - ${this.state.salaryMax}
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          min={0}
                          max={500000}
                          onChange={(d) => this.onSliderChange("salary", d)}
                          marks={{
                            0: "$0",
                            500000: "$500,000+",
                          }}
                          step={5000}
                          defaultValue={[
                            this.state.salaryMin,
                            this.state.salaryMax,
                          ]}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>Job Level</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobLevel"
                          value={JSON.stringify(this.state.jobLevel)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Job Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentType"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.employmentType)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.employmentTypes.map((employmentType) => (
                            <option value={JSON.stringify(employmentType)}>
                              {employmentType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Company Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="companyType"
                          value={JSON.stringify(this.state.companyType)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.companyTypes.map((companyType) => (
                            <option value={JSON.stringify(companyType)}>
                              {companyType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Office Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="officeType"
                          value={JSON.stringify(this.state.officeType)}
                          defaultValue={JSON.stringify({})}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify("")}>
                            Choose a field
                          </option>
                          {officeTypes.map((officeType) => (
                            <option value={JSON.stringify(officeType)}>
                              {officeType}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Row>
                          <Form.Label>Total Years Work Experience</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {this.state.workExperienceMin} years -{" "}
                              {this.state.workExperienceMax} years
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          defaultValue={[
                            this.state.workExperienceMin,
                            this.state.workExperienceMax,
                          ]}
                          min={0}
                          max={25}
                          onChange={(d) => this.onSliderChange("experience", d)}
                          marks={{
                            0: "0",
                            25: "25+ Yrs",
                          }}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                          ]}
                          handleStyle={[
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                              borderColor: "rgba(39, 129, 52, 1)",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Job Location</Form.Label>
                        <GeoSelectCity
                          isMulti={false}
                          name="cities"
                          handleChange={this.handleSelectChange}
                          value={this.state.cities}
                          placeholder="Enter City and State"
                        />
                      </Col>
                    </Form.Row>

                    {this.state.cities.length !== 0 && (
                      <Form.Row className="mt-5">
                        <Col>
                          <Form.Row>
                          <Form.Label>
                              Mile Radius Around {this.state.cities[0].label}
                            </Form.Label>
                            <SliderValueContainer>
                              <SliderValue>
                                {this.state.searchDistanceMin === 400
                                  ? "Nationwide"
                                  : this.state.searchDistanceMin + "mi"}
                              </SliderValue>
                            </SliderValueContainer>
                          </Form.Row>
                          <Slider
                            min={0}
                            max={400}
                            onChange={(d) =>
                              this.onSliderChange("searchDistance", d)
                            }
                            marks={{
                              0: "0mi",
                              400: "Nationwide",
                            }}
                            defaultValue={this.state.searchDistanceMin}
                            tipFormatter={(value) => `${value}%`}
                            railStyle={{
                              backgroundColor: "#f5f5f5",
                              height: 10,
                            }}
                            trackStyle={[
                              { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                              { background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)", height: 10 },
                            ]}
                            handleStyle={[
                              {
                                background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                borderColor: "rgba(39, 129, 52, 1)",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                              {
                                background: "linear-gradient(90deg, rgba(71, 168, 54, 1) 0%, rgba(39, 129, 52, 1) 100%)",
                                borderColor: "rgba(39, 129, 52, 1)",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                            ]}
                            dotStyle={{
                              display: "none",
                            }}
                          />
                        </Col>
                      </Form.Row>
                    )}

                    <Form.Row className="mt-5">
                      <Button
                        variant="outline-primary"
                        className="  mr-2"
                        onClick={() => this.props.handleModalShow("")}
                      >
                        Cancel
                      </Button>

                      <Button className="ml-auto" onClick={this.handleNext}>
                        Next
                      </Button>
                    </Form.Row>
                  </ModalColumn>
                </ModalContainer>
              ),
              6: (
                <ModalContainer>
                  <ModalColumn>
                    <img
                      style={{
                        padding: 50,
                        width: 400,
                      }}
                      src={require("images/modals/recruiter-onboarding-1.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="w-100 p-5 bg-white">
                    <h1>Skills Evaluator</h1>
                    <p>
                      The Skills Evaluator allows you to evaluate the top 5
                      skills that you need to know from candidates in this
                      Search. When you match with one candidate, or multiple
                      candidates, they will be asked to evaluate how well-versed
                      they are in each skill when they receive your match
                      request.
                    </p>
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            What is your level of expertise in:
                          </Form.Label>
                          <Form.Control
                            name="skills"
                            value={this.state.skills}
                            onChange={this.handleChange}
                            type="text"
                            placeholder="Add up to 5 skills"
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row
                        className="flex mt-4 "
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {this.state.loading ? (
                          <Button className="">Loading...</Button>
                        ) : (
                          <Button className="" onClick={this.handleCreateSearchQuery}>
                            Run Search with Skills Evaluation
                          </Button>
                        )}
                        {this.state.loading ? (
                          <p
                            style={{
                              color: "#47A836",
                              fontSize: 12,
                              fontWeight: "bold",
                              textDecoration: "underline",
                              marginTop: 10,
                              cursor: "pointer",
                            }}
                          >
                            Loading...
                          </p>
                        ) : (
                          <p
                            onClick={(e) =>
                              this.handleCreateSearchQuery({ skipSkills: true })
                            }
                            style={{
                              color: "#47A836",
                              fontSize: 12,
                              fontWeight: "bold",
                              textDecoration: "underline",
                              marginTop: 10,
                              cursor: "pointer",
                            }}
                          >
                            Skip Skills Evaluation and Run Search
                          </p>
                        )}
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
            }[this.state.onboarding.currentStep]
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default RecruiterOnboarding;

const BackgroundImg = styled.div`
  width: 300px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  background: #edeff0;
`;

const SliderValueContainer = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
  Spinner
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";

// Link
// Category
// Title
// Description
// Company
// Location

class Jobs extends Component {
  state = {
    desiredCities: [],
    rssUrl: "https://cdn.recruitology.com/job_board/site_maps/254_jobs_feed_lite_rss.xml",
    rssItems: []
  };

  async intakeRSS() {
    const text = await fetch(this.state.rssUrl).then((res) => res.text());
    const xmlDoc = new DOMParser().parseFromString(text, "text/xml");
    const items = Array.from(xmlDoc.querySelectorAll("item")).map((job) => {

    return ({
      title: job.querySelector("title").textContent.split('-')[0].replace(/\s+/g, ' ').trim(),
      url: job.querySelector("link").innerHTML,
      category: job.querySelector("category").innerHTML,
      company: job.querySelector("CompanyName").innerHTML,
      location: job.querySelector("Location").innerHTML.split('(')[0].replace(/\s+/g, ' ').trim(),
      description: job.querySelector("description").childNodes[0].data
    })
    
    });

    const filteredItemsByDesiredLocation = items.filter((job) => (this.state.desiredCities.some((desiredCity) => desiredCity.includes(job.location))))
    this.setState({ 
      rssItems: filteredItemsByDesiredLocation
    });
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
        this.setState({
          desiredCities: response.data.desiredCities.map((location) => location.value)
        })
      }).then(() => {
        this.intakeRSS();
      })
    }, 450);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <JobsContainer>
              <JobsHeading>Cannabis Insider Jobs Talent Community Hiring Partners</JobsHeading>
              <JobsHeadingDetail>
                The below job listings are based on your profile desired locations that you input
                into your profile from Cannabis Insider Jobs Talent Community's hiring partners. 
                If you want to see jobs in different areas, please go update your profile.
              </JobsHeadingDetail>
              <JobsHeadingDetail>
                These are not affilitated with actual Cannabis Insider Jobs Talent Community
                Recruiters, but serve as an add-on feature to help accelerate
                your job search.
              </JobsHeadingDetail>

              <JobsList>
                {this.state.rssItems.length > 0 ? this.state.rssItems.map((job) => {
                    return (
                    <JobItemContainer>
                      <JobItemImage src={require("images/thirdpartyjobs.svg")} />
                      <JobTitle>{job.title}</JobTitle>
                      <div
                        style={{
                          height: 70,
                        }}
                      >
                        <JobCompany>{job.company}</JobCompany>
                        <JobLocation>
                          {/* {job.city}, {job.location} */}
                          {job.location}
                        </JobLocation>
                      </div>
                      {/* <JobSalary>$100,000</JobSalary> */}
                      <a href={job.url} target="_blank">
                        <Button>
                          Apply
                        </Button>
                      </a>
                    </JobItemContainer>
                    )}
                ) : (<JobsHeadingDetail style={{marginTop: "50px"}}>0 jobs listings found in your desired location(s)</JobsHeadingDetail>)}
              </JobsList>
            </JobsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const JobsContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
  padding: 40px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 55px;
`;

const JobsHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const JobsHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #47A836;
  font-size: 14px;
`;

const JobsListContainer = styled.div``;
const JobsListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const JobsItemImage = styled.img``;
const JobsItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const JobsItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const JobsItemJobTitle = styled.p`
  color: #47A836;
  font-size: 14px;
  margin: 0;
`;
const JobsItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const NullJobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  h1 {
    color: #999 !important;
  }
  p {
    color: #999;
    font-size: 16px;
  }
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

const JobsHeading = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const JobsHeadingDetail = styled.p`
  font-size: 14px;
  color: #999;
`;

const JobsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 25px;
  margin-top: 30px;
`;

const JobItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #f5f5f5;
  padding: 20px;
  justify-content: space-between;
  width: 220px;

  // flex-direction: space-between;
`;

const JobItemImage = styled.img`
  width: 60px;
  height: 60px;
`;
const JobTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
`;
const JobCompany = styled.p`
  font-size: 14px;
  margin: 0;

  text-align: center;
`;
const JobLocation = styled.p`
  font-size: 14px;
  margin: 0;
  margin-top: 3px;
  text-align: center;
`;
const JobSalary = styled.p`
  font-size: 14px;
  margin: 0;
`;
const JobLinkContainer = styled.div`
  background-color: #47A836 !important;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
`;
const JobLinkText = styled.p`
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

export default Jobs;

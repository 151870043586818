import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import mycarepathPrivacy from '../../constants/gigavoltPrivacyPolicy';


function PrivacyContentTemplate(props) {
  const history = useHistory();
  const handleBackArrow = () => {
    history.goBack();
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [])

  return (
    <Grid
      container
      style={{
        minHeight: '100vh',
        paddingTop: '100px',
        paddingBottom: '50px',
        backgroundColor: '#ccc',
      }}
    >
      <Grid
        item
        xs={1}
      />
      <Grid
        className={classes.privacyInnerContainer}
        item
        xs={10}
      >
        <div
          className={classes.privacyBackButton}
          onClick={() => handleBackArrow()}
        >
          <ArrowBackIcon />
          <Typography
            sx={{
              marginLeft: '10px',
            }}
          >
            Back
          </Typography>
        </div>
        { mycarepathPrivacy() }
      </Grid>
    </Grid>
  )
}

PrivacyContentTemplate.propTypes = {}

export default PrivacyContentTemplate

import React from "react";
import { Col, ListGroup, Row, Badge } from "react-bootstrap";
import styled from "styled-components";
import CircleIcon from '@material-ui/icons/Brightness1';

const PriceTag = ({selectedPrice}) => {
  return (
    <Row className="bg-white p-3 align-items-center justify-content-start rounded-lg">
        <img width="85px" height="85px" alt='subscription_img' src={require("images/logo/cji-green-icon.png")}/>
        <div className="pl-3">
          <span style={{fontSize: 20}}>Subscription</span>
          <span style={{ fontSize: 18, fontWeight: 600, display: "block" }}>
            ${selectedPrice.unit_amount / 100}/{selectedPrice.recurring.interval}
          </span>
        </div>
    </Row>
  )
}

const BulletedListItem = ({text}) => (
  <ListGroup.Item className="bg-transparent border-0" as="li">
    <CircleIcon style={{fontSize: 18, paddingRight: 10, paddingBottom: 2, marginLeft: '-1.15rem', color: '#47A836'}}/>
    {text}
  </ListGroup.Item>
)

export default function ProductCard(props) {
  const { selectedPrice } = props;

  return (
    <>
        <Row>
          <Col className="p-0">
            <ProductCardTop>
                  Subscribe to CannabisInsiderJobs.com Talent Community,
                  a cannabis-industry focused professional growth network.
                  {/* <br /> */}
                  {/* <span style={{fontSize: 20, fontWeight: 500, paddingTop: 10}}>
                  Discover talent for your organization’s hiring needs today - no job posting required!
                  </span> */}
               </ProductCardTop>
          </Col>
        </Row>
        <Row style={{backgroundColor: "#edeff3"}}>
          <Col xs={1}/>
          <Col xs={10}style={{top: -55}}>
            <PriceTag selectedPrice={selectedPrice}/>
          </Col>
          <Col xs={1}/>
        </Row>
        <Row style={{backgroundColor: "#edeff3", paddingBottom: "40px"}}>
          <Col xs={1}/>
          <Col xs={10} style={{ top: -20 }}>
            <ListGroup as="ol" style={{ listStyleType: 'decimal !important;' }}>
              <BulletedListItem text={'Discover talent with cannabis-related experience, or those who desires to work in the cannabis industry, today'} />
              <BulletedListItem text={'Utilize our desires-based matching algorithm to connect with talent that match your hiring needs'} />
              <BulletedListItem text={'Have conversations with active AND passive talent looking for career opportunities near you'} />
              <BulletedListItem text={'Preview communication styles of potential candidates before connecting'} />
            </ListGroup>
          </Col>
          <Col xs={1}/>
        </Row>
      </>
  );
}

const ProductCardTop = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  background-image: url("../images/cannabis-checkout-bg.jpg");
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.55);
  padding-top: 100px;
  padding-bottom: 130px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import data from './constants/content';
import InfoLandingTemplate from './components/InfoLandingTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingInfo(props){
  return (
    <>
      <InfoLandingTemplate
        {...data}
      />
      <TemplateFooter
        {...data}
      />
    </>
  )
}

LandingInfo.propTypes = {}

export default LandingInfo;
